import moment from 'moment';

const presetFns = {
  today: () => {
    const from = moment().startOf('day').toDate();
    const to = moment().endOf('day').toDate();
    return [from, to];
  },
  lastWeek: () => {
    const from = moment().subtract(7,'d').toDate();
    const to = moment().toDate();
    return [from, to];
  },
  lastMonth: () => {
    const from = moment().subtract(1,'month').toDate();
    const to = moment().toDate();
    return [from, to];
  },
};

// export const presets = Object.keys(presetFns);

export default presetFns;
