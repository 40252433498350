export const getLS = (a) => {
  try {
    return JSON.parse(window.localStorage.getItem(a));
  } catch (e) {
    return null;
  }
};

export const setLS = (a, b) => {
  if (b === null) window.localStorage.removeItem(a);
  else window.localStorage.setItem(a, JSON.stringify(b));
};

export const emptyLS = () => {
  window.localStorage.clear();
};

export const removeAccountData = () => {
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('user');
  window.localStorage.removeItem('expiresAt');
};
