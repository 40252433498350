import { take, fork, cancel, call, put, select } from 'redux-saga/effects';
import { getUserDetails, getToken } from '../selectors';
import { apiPatchMessaging } from './callApi';
import apiRoutes from '../../constants/apiRoutes';
import { successReadMessage } from '../actionCreators/messages';
import types from '../actionTypes';

function* markMessageAsRead({ messageId }) {
  try {
    const token = yield select(getToken);
    const user = yield select(getUserDetails);
    const params = {
      status: 'read',
      accountId: user.id,
      messageId,
      token,
    };
    const response = yield call(apiPatchMessaging, apiRoutes.READ_MESSAGE, params);
    yield put(successReadMessage(messageId));
  } catch (e) {
    console.log('Failed to change the message status.');
  }
}

export default function* watchReadMessage() {
  let forked = null;
  while (true) {
    const action = yield take(types.READ_MESSAGE_REQUEST);
    if (forked) yield cancel(forked);
    yield fork(markMessageAsRead, action);
  }
}
