import types from '../actionTypes';

export const loginRequest = (username, password) => ({
  type: types.LOGIN_REQUEST,
  username,
  password,
});

export const loginSuccess = (token, user) => ({
  type: types.LOGIN_SUCCESS,
  token,
  user,
});

export const loginRequestWithToken = () => ({
  type: types.LOGIN_REQUEST,
});

export const loginFailure = (e) => ({
  type: types.LOGIN_FAILURE,
  e,
});

export const loginReset = () => ({
  type: types.LOGIN_RESET,
});

export const logout = () => ({
  type: types.LOGOUT,
});

export const authRequest = (googleToken) => ({
  type: types.AUTH_REQUEST,
  googleToken
});
