import types from '../actionTypes';

export const registerRequest = (username, password) => ({
  type: types.REGISTER_REQUEST,
  username,
  password,
});

export const registerSuccessReset = () => ({
  type: types.REGISTER_SUCCESS_RESET,
});

export const registerSuccess = (message) => {
  return {
    type: types.REGISTER_SUCCESS,
    message,
  };
};

export const registerFailure = (message) => {
  return {
    type: types.REGISTER_FAILURE,
    message,
  };
};
