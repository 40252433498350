// const settings = {
//   API_URL: 'https://stagingcasinotoursapi.pokerplaza.com',
//   API_MESSAGING_URL: 'https://staging-newsserviceapi.pokerplaza.com/api',
//   SILVER_HOME_URL: 'https://apps2.pokerplaza.com/coinbar',
//   SOCKET_URL: 'https://stagingcasinotoursapi.pokerplaza.com/',
//   SOCIAL_TOURNAMENT_MODULE_URL: 'https://planet-casinotourmodule.draft10.com',
//   ACCOUNTING_API: 'https://stagingapi.pokerplaza.com',
//   MESSAGES_URL: 'https://staging-newsserviceapi.pokerplaza.com/',
//   skinId: 581719,
//   TIPSTER_SDK: 'https://betonpools.com/sdk/tipster-sdk.js?tlgid=33'
// };

// export default settings;


const settings = {
  API_URL: 'https://casinotoursapi.draftplaza.com',
  API_MESSAGING_URL: 'https://newsservice.draftplaza.com/api',
  SILVER_HOME_URL: 'https://apps2.pokerplaza.com/coinbar',
  SOCKET_URL: 'https://casinotoursapi.draftplaza.com/',
  SOCIAL_TOURNAMENT_MODULE_URL: 'https://gosjackpot.draftplaza.com',
  ACCOUNTING_API: 'https://webapi.draftplaza.com',
  MESSAGES_URL: 'https://newsservice.draftplaza.com/',
  TIPSTER_SDK: 'https://betonpools.com/sdk/tipster-sdk.js?tlgid=40',
  skinId: 770893,
};

export default settings;
