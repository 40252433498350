import { createSelector as cs } from 'reselect';
import { prop } from '../helpers/pureFunctions';

//example
// const getTestData = prop('testData)
// export const getTest = cs(getTestData, prop('data'))

const auth = prop('auth');
const flags = prop('flags');
const getAccountBalance = prop('getAccountBalance');
const messages = prop('messages');
const dialogManager = prop('dialogManager');
const transactions = prop('transactions');

// Authentication

export const getUserDetails = cs(auth, prop('user'));
export const getToken = cs(auth, prop('token'));
export const isAuthPending = cs(auth, prop('pending'));
export const isAuthenticated = cs(auth, prop('authenticated'));
export const hasToken = cs(auth, (a) => a.token !== null);

export const getFPPWallet = cs(getUserDetails, (u) => (u ? u.wallets.find((w) => w.currency === 'FPP') : null));

export const getRegisterSuccessDialogStatus = cs(flags, (f) => f.registerSuccessDialogStatus);

export const isBalancePending = cs(getAccountBalance, prop('pending'));

export const getMessages = messages;
export const getTourFinishedMessages =
  cs(messages, (messages) => messages?.filter((m) => m.templateId === 'tourFinished')) || [];
export const getNewBonusMessages =
  cs(messages, (messages) => messages?.filter((m) => m.templateId === 'newBonus')) || [];

export const getDialogs = cs(dialogManager, prop('dialogs'));

export const getTransactions = cs(transactions, prop('data'));
export const isTransactionsPending = cs(transactions, prop('pending'));
