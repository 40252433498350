import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Logo from '../common/Logo';
import UserInfo from '../UserInfo';
import Flag from './Flag';
import Button from '../common/Button';
import BurgerMenu from './BurgerMenu';
import Navigation from './Navigation';
import { useSelector } from 'react-redux';
import { getToken } from '../../store/selectors';
import './Header.scss';

const Header = ({ isMobile }) => {
  const { t } = useTranslation();
  const isAuth = useSelector(getToken);
  const history = useHistory();
  const [isOpenBurgerMenu, setIsOpenBurgerMenu] = useState(false);

  const onRedirect = (path) => {
    history.push(path);
  };

  const handleClick = () => {
    if (isOpenBurgerMenu) {
      setIsOpenBurgerMenu(false);
    }
    history.push(isAuth ? '/my-account' : '/login');
  };

  const onClickLogoHandler = () => {
    if (isOpenBurgerMenu) {
      setIsOpenBurgerMenu(false);
    }
    history.push('/');
  };

  const handleClickBurgerMenu = () => {
    setIsOpenBurgerMenu((prev) => !prev);
  };

  return (
    <div className='header-wrapper'>
      <div className='header-container'>
        <div className='header__logo'>
          <div className='logo-wrapper'>
            <div onClick={onClickLogoHandler} className="logo">
              <Logo />
            </div>
          </div>
        </div>
        {!isMobile && <Navigation />}
        <div className='header__login'>
          {isAuth ? (
            <UserInfo />
          ) : (
            <>
              <Button variant='secondary-rounded' onClick={() => onRedirect('/login')}>
                {t('logIn')}
              </Button>
              <Button variant='primary-rounded' onClick={() => onRedirect('/register')}>
                {isMobile ? t('registration') : t('freeRegister')}
              </Button>
            </>
          )}
         {!isMobile &&  <div className="header__flag">
            <Flag />
          </div>}
        </div>

        {isMobile && <BurgerMenu isOpenBurgerMenu={isOpenBurgerMenu} handleClickBurgerMenu={handleClickBurgerMenu} />}
      </div>
    </div>
  );
};

export default Header;
