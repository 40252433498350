import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTourFinishedMessages, getNewBonusMessages } from '../store/selectors';
import { openDialog } from '../store/actionCreators/dialogManager';

export const useMessages = () => {
  const tourFinishedMessages = useSelector(getTourFinishedMessages);
  const newBonusMessages = useSelector(getNewBonusMessages);
  const dispatch = useDispatch();
  useEffect(() => {
    [
      { id: 'tourFinished', messages: tourFinishedMessages },
      { id: 'newBonus', messages: newBonusMessages },
    ].forEach(({ id, messages }) => {
      if (messages.length) {
        messages.forEach((m) => {
          dispatch(
            openDialog({
              dialogType: `${id}_${m._id}`,
              info: m,
            })
          );
        });
      }
    });
  }, [tourFinishedMessages, newBonusMessages]);
};
