import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { transactionsRequest, transactionsReset } from '../../../store/actionCreators/transactions'
import TransactionHistoryTable from './TransactionHistoryTable';
import TransactionHistoryFilters from './TransactionHistoryFilters';
import './TransactionHistory.scss';

const TransactionHistory = () => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    type: 'all',
  })

  useEffect(() => {
    if (form?.from && form?.to) {
      dispatch(transactionsRequest(form));
    }
    return () => dispatch(transactionsReset());
  }, [form]);

  return (
    <div className="transaction-history">
      <div className="transaction-history__filters-wrapper">
        <TransactionHistoryFilters form={form} setForm={setForm} />
      </div>
      <div className="transaction-history__table-wrapper">
        <TransactionHistoryTable type={form.type} />
      </div>
    </div>
  );
};

export default TransactionHistory;
