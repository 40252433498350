import { take, fork, cancel, call, put, select } from 'redux-saga/effects';
import { accountingApiGet } from './callApi';
import apiRoutes from '../../constants/apiRoutes';
import { getToken, getUserDetails } from '../selectors';
import { getAccountBalanceFailure, getAccountBalanceSuccess } from '../actionCreators/getAccountBalance';
import types from '../actionTypes';
import { getLS, setLS } from '../../helpers/localStorage';

function* processGetAccountBalanceRequest() {
  try {
    const token = yield select(getToken);
    const user = yield select(getUserDetails);
    const params = { token };

    const response = yield call(accountingApiGet, apiRoutes.getAccountBalance(user.id), params);
    if (response.data.success) {
      yield put(getAccountBalanceSuccess(response.data.result.wallets));
      const user = JSON.parse(getLS('user'));
      setLS('user', JSON.stringify({ ...user, wallets: response.data.result.wallets }));
    } else {
      yield put(getAccountBalanceFailure());
    }
  } catch (e) {
    yield put(getAccountBalanceFailure(e));
  }
}

function* watchGetAccountBalanceRequest() {
  let forked = null;
  while (true) {
    yield take(types.GET_ACCOUNT_BALANCE_REQUEST);
    if (forked) yield cancel(forked);
    forked = yield fork(processGetAccountBalanceRequest);
  }
}

export default function* getAccountBalanceRequests() {
  yield call(watchGetAccountBalanceRequest);
}
