import { useState, useEffect, useRef } from 'react';
import AccountDetailsMobile from './components/AccountDetailsMobile';
import { useSelector, useDispatch } from 'react-redux';
import TransactionHistoryMobile from './components/TransactionHistoryMobile';
import { getFPPWallet, getUserDetails, isBalancePending } from '../../../store/selectors';
import { useTranslation } from 'react-i18next';
import stepperJSON from '../stepper.json';
import FPP from '../../../components/FPP';
import './MyAccountMobile.scss';
import Logout from '../../Logout';
import { getAccountBalanceRequest } from '../../../store/actionCreators/getAccountBalance';
import { classNames, goToTop } from '../../../helpers/pureFunctions';
import Input from '../../common/Input';
import { useFormatCurrency } from '../../../hooks/useCorrency';

const MyAccountMobile = () => {
  const { t } = useTranslation();
  const { playerInfo } = stepperJSON;
  const [isActive, setIsActive] = useState(0);
  const userDetails = useSelector(getUserDetails);
  const FPPWallet = useSelector(getFPPWallet);
  const dispatch = useDispatch();
  const { username, id, nickname } = userDetails;
  const [hasAnimation, setAnimation] = useState(false);
  const pending = useSelector(isBalancePending);
  const sectionNode = useRef();
  const formatCurrency = useFormatCurrency();

  const getBalance = () => {
    dispatch(getAccountBalanceRequest());
    setAnimation(true);
  };

  const handleMessage = (event) => {
    if (event.data.name === 'balanceUpdate') {
      getBalance();
    }
  };

  useEffect(() => {
    let timer;
    if (hasAnimation) {
      timer = setTimeout(() => {
        setAnimation(false);
      }, 400);
    }
    return () => clearTimeout(timer);
  }, [hasAnimation]);

  useEffect(() => {
    if (sectionNode.current) {
      const yOffset = -90; 
      const y = sectionNode.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y});
    }
  }, [isActive, sectionNode]);

  useEffect(() => {
    goToTop();
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const userInfoDetailedCircleClassNames = classNames('user-info__circle', 'icon-loop', {
    'user-info__circle--spinner': hasAnimation || pending,
  });

  const handleClick = (info) => {
    if (info.disabled) return;
    if (isActive === info.id) return setIsActive(0);
    setIsActive(info.id);
  };


  const renderSection = (id) => {
    switch (id) {
      case 1:
        return <AccountDetailsMobile />;
      case 2:
        return <TransactionHistoryMobile />;
      default:
        return;
    }
  };

  return (
    <div className="my-account__mobile">
      <div className="my-account__mobile-player-info">
        <div className="my-account__mobile-player-info-col">
          <span className="my-account__mobile-player-name">{nickname || username}</span>
        </div>
        <div className="my-account__mobile-player-info-col">
          <span className="my-account__mobile-player-id">{`${t('id')}: ${id}`}</span>
          <div className="my-account__mobile-player-balance">
            <i className={userInfoDetailedCircleClassNames} onClick={getBalance} />
            <span className='my-account__mobile-player-balance-label'>{`${t('balance')}:`}</span>
          <div className="my-account__mobile-player-balance-value-wrapper">
            <span style={{ fontWeight: 700, fontSize: 16, color: '#78be4b' }}>{formatCurrency(userDetails?.wallets?.[0]?.balance, userDetails?.wallets?.[0]?.currency)}</span>
          </div>
          </div>
        </div>
        <div className="account-details__section-input-wrapper">
          <Input
            disabled={true}
            property="email"
            value={userDetails.personalInfo?.email}
            variant="primary"
          />
        </div>
      </div>
      <div className="my-account__mobile-list" ref={sectionNode}>
        {playerInfo.map((info) => {
          const isOpen = info.id === isActive;

          return (
            <div  key={info.id} className='my-account__mobile-item-wrapper'>
              <div
                className={`my-account__mobile-item ${isActive === info.id ? 'my-account__mobile-item--active' : ''} ${info.disabled ? 'my-account__mobile-item--disabled' : ''}`}
                onClick={() => handleClick(info)}
              >
               <div className="my-account__mobile-item-icon-wrapper">
                <i className={`icon-${info.icon} my-account__mobile-item-icon`} />
               </div>
                <span className='my-account__mobile-item-title'>{t(info.title)}</span>
                <i className='icon-arrow my-account__mobile-item-arrow' />
              </div>
              {isOpen && renderSection(isActive)}
            </div>
          );
        })}
        <div className="my-account__mobile-logout">
          <Logout variant="logout-mobile" />
        </div>
      </div>
    </div>
  );
};

export default MyAccountMobile;
