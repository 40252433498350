import { t } from 'i18next';
import { isRequired, isLettersNumbersOrUnderscores } from './pureFunctions';

const generateRequiredValidator = (field) => {
  return {
    isLettersNumbersOrUnderscores: {
      errorMessage: t('errors.lettersNumbersOrUnderscoresOnly'),
      check: (value) => isLettersNumbersOrUnderscores(value),
    },
    isRequired: {
      errorMessage: t('errors.isRequired', { field }),
      check: (value) => isRequired(value),
    },
  };
};

export const createLoginValidator = () => {
  return () => ({
    username: generateRequiredValidator('Username'),
    password: generateRequiredValidator('Password'),
  });
};

export const createRegisterValidator = (form) => {
  const errors = {
    username: generateRequiredValidator('Username'),
    password: generateRequiredValidator('Password'),
    email: {
      isRequired: {
        errorMessage: t('errors.isRequired', { field: 'Email' }),
        check: (value) => isRequired(value),
      },
    },
    repeatPassword: {
      isMatch: {
        errorMessage: t('errors.passowrdsMustMatch'),
        check: () => form.password !== form.repeatPassword,
      },
      ...generateRequiredValidator('Repeat Password'),
    },
  };

  return () => errors;
};
