import { take, fork, cancel, call, put, select } from 'redux-saga/effects';
import { accountingApiGet } from './callApi';
import apiRoutes from '../../constants/apiRoutes';
import types from '../actionTypes';
import { transactionsSuccess, transactionsFailure } from '../actionCreators/transactions';
import { getToken } from '../selectors';

function* processTransactionsRequest({ form }) {
  try {
    const token = yield select(getToken);
    const params = {
      token,
      type: form.type === 'depositWithdrawals' ? 'movements' : form.type,
      dateFrom: form.from,
      dateTo: form.to,
    };
    switch(form.type) {
      case 'depositWithdrawals':
        params.type = 'movement';
        break;
      case 'gameTransfers':
        params.type = 'gameMovement'
        break;
      case 'all':
      default:
        params.type = 'all';
    };

    const response = yield call(accountingApiGet, apiRoutes.GET_TRANSACTIONS, params);
    yield put(transactionsSuccess(response.data.result.movements));
  } catch (e) {
    yield put(transactionsFailure(e));
  }
}

function* watchTransactionsRequest() {
  let forked = null;
  while (true) {
    const action = yield take(types.GET_TRANSACTIONS_REQUEST);
    if (forked) yield cancel(forked);
    forked = yield fork(processTransactionsRequest, action);
  }
}

export default function* transactionsRequests() {
  yield call(watchTransactionsRequest);
}
