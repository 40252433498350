import React from 'react';
import './RotateLoader.scss';

const RotateLoader = () => {
  return (
    <div className='rotate_loader'>
      <div className='rotate_loader--img-wrapper'>
        <img className='rotate_loader--img' src='./images/global/rotate.png' />
      </div>
    </div>
  );
};

export default RotateLoader;
