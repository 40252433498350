import { useState, useEffect } from 'react';
import moment from 'moment';
import presetFns from '../../../helpers/presetFns';
import Button from '../../common/Button';
import Input from '../../common/Input';
import './Presets.scss';
import { isValidDate } from '../../../helpers/pureFunctions';
import { disableFutureDates } from '../../../helpers/disableFutureDates';
import { useCallback } from 'react';
import { t } from 'i18next';

const Presets = ({ setForm, form }) => {
  const [value, setValue] = useState('today');

  useEffect(() => {
    if (!form?.from && !form?.to) {
      setDate(value);
    }
  }, [form?.from, , value])

  const setDate = (value) => {
    if (value === 'range') {
      setForm(prev => ({ ...prev, from: moment().startOf('day').toDate(), to: moment().endOf('day').toDate() }));
    } else {
      const [from, to] = presetFns[value]();
      setForm(prev => ({ ...prev, from, to }));
    }
  };
  
  const handleClick = useCallback((value) => {
    setValue(value);
    setDate(value);
  }, []);

  const setRangeValue = useCallback((value, id) => {
    setForm(prev => ({ ...prev, [id]: id === 'from' ? moment(value).startOf('day').toDate() : moment(value).endOf('day').toDate() }));
  }, []);

  const getFromToValue = (date) => {
    const [y, m, d] = new Date(date).toISOString().substring(0, 10).split('-');
    const has31  = +m === 8 ? true : +m % 2 !== 0;
    if (+m === 2) {
      if (+d + 1 > 28) {
        return `${y}-${String(+m + 1).padStart(2, 0)}-01`;
      } else {
        return `${y}-${m}-${String(+d + 1).padStart(2, 0)}`;
      }
    }
    if (+d + 1 > (has31 ? 31 : 30)) {
      return `${y}-${String(+m + 1).padStart(2, 0)}-01`;
    } else {
      return `${y}-${m}-${String(+d + 1).padStart(2, 0)}`;
    }
  };


  return (
    <div className='presets'>
      <div className="presets__content">
        <div className="presets__button-filters">
          {[{label: 'last24H', val: 'today'}, {label: 'lastWeek', val: 'lastWeek'}, {label: 'lastMonth', val: 'lastMonth'}, {label: 'range', val: 'range'}].map((item) => (
            <div className={`presets__button-filter ${item.val === value ? 'presets__button-filter--active' : ''}`} key={item.val}>
              <Button onClick={handleClick.bind(null, item.val)}>
                <span>{t(item.label)}</span>
              </Button>
            </div>
          ))}
        </div>
      </div>
      {value === 'range' && (
        <div className="presets__range-wrapper">
          <Input
            value={(isValidDate(new Date(form.from)) && getFromToValue(form.from) || new Date().toISOString().substring(0, 10))}
            property="from"
            type="date"
            callbackFn={setRangeValue}
            others={{ max: disableFutureDates() }}
            />
          <Input
            value={(isValidDate(new Date(form.to)) && new Date(form.to).toISOString().substring(0, 10)) || new Date().toISOString().substring(0, 10)}
            property="to"
            type="date"
            callbackFn={setRangeValue}
          />
        </div>
      )}
    </div>
  );
};

export default Presets;
