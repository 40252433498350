const languageConfig = {
  lng: ['it', 'en'],
  fallbackLng: ['it', 'en'],
  fallbackNS: 'common',
  lowerCaseLng: true,
  ns: 'common',
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
  },
  keySeparator: '.',
};

export default languageConfig;
