import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header';
import { useLanguageChange } from '../../hooks/useLanguageChange';
import languageSettings from '../../configs/language';
import Rules from '../Rules';
import Guide from '../Guide';
import HowToPlayPage from '../HowToPlayPage';
import Login from '../Login';
import News from '../News';
import Home from '../Home';
import Tripser from '../Tripser';
import Register from '../Register';
import MyAccountDesktop from '../myAccount';
import MyAccountMobile from '../myAccount/myAccountMobile';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import { classNames } from '../../helpers/pureFunctions';
import useMessagesSocket from '../../hooks/useMessagesSocket';
import Dialogs from '../Dialogs';
import { useMessages } from '../../hooks/useMessages';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../store/selectors';
import './app.scss';

import { GoogleOAuthProvider } from '@react-oauth/google';
import RotateLoader from '../common/RotateLoader';
import { loginRequestWithToken } from '../../store/actionCreators/authentication';
import { useEffect, useState } from 'react';

const FIXED_FOOTER_ROUTES = ['/tipster'];

const App = () => {
  const [tipsterInitialized, setTipsterInitialized] = useState(false);
  const dispatch = useDispatch();
  useMessagesSocket();
  useMessages();
  useLanguageChange(languageSettings.lng[0]);
  const { isLandscape } = useMobileOrientation();
  const token = useSelector(getToken);
  const { pathname } = useLocation();
  const renderCheck = FIXED_FOOTER_ROUTES.includes(pathname) ? (isMobile ? false : true) : true;

  const appClasses = classNames('app-wrapper', {
    tipster: location.pathname === '/tipster',
    mobile: isMobile,
  });


  const handleTipsterInitialized = () => {
    setTipsterInitialized(true);
  };

  useEffect(() => {
    if (tipsterInitialized) {
      dispatch(loginRequestWithToken())
    }
  }, [tipsterInitialized]);

  return (
    <>
      {isMobile && isLandscape && !pathname.includes('tipster') ? (
        <RotateLoader />
      ) : (
        <GoogleOAuthProvider clientId='324532454515-jrmm3hchobpfsvvhkf9os3912p1ujfnh.apps.googleusercontent.com'>
          <Dialogs />
          <div className={appClasses}>
            <Header isMobile={isMobile} />
            <div className='app__main-content'>
              <Switch>
                <Route path='/guide' component={HowToPlayPage} />
                <Route path='/news' component={News} />
                <Route path='/rules' component={Rules} />
                <Route path='/tipster' component={Home} />
                <Route path='/register' component={Register} />
                <Route path='/login' component={Login} />
                {token && <Route path='/my-account' component={isMobile ? MyAccountMobile : MyAccountDesktop} />}
                <Route path={['/', '/home']} component={Home} exact />
                <Redirect to='/' />
              </Switch>
              {renderCheck && <Footer fixedFooterRoutes={FIXED_FOOTER_ROUTES} />}
              <Tripser onTipsterInitialized={handleTipsterInitialized} />
            </div>
          </div>
        </GoogleOAuthProvider>
      )}
    </>
  );
};

export default App;
