import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { configs } from '../../../../configs/configs';

const DescriptionWrapper = ({ data }) => {
  const [toggleText, setToggleText] = useState(false);
  const { t } = useTranslation();
  const WEBSITE_NAME = configs.websiteName;

  const showToggleHandler = () => {
    setToggleText((prev) => !prev);
  };
  return (
    <div className='element__description-wrapper'>
      {toggleText ? (
        data.description.map((el) => (
          <p className='text'>{t(`StepsToPlayWidget.${data.stepNumber}.${el}`, { websiteName: WEBSITE_NAME })}</p>
        ))
      ) : (
        <span>{t(`StepsToPlayWidget.${data.stepNumber}.${data.shortDescription}`, { websiteName: WEBSITE_NAME })}</span>
      )}
      <span onClick={showToggleHandler} className='show-more'>
        {t(toggleText ? 'showLess' : 'showMore')}
      </span>
    </div>
  );
};

export default DescriptionWrapper;
