import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import textContent from './textContent.json';
import { goToTop } from '../../helpers/pureFunctions';
import { isMobile } from 'react-device-detect';
import './Rules.scss';
import { configs } from '../../configs/configs';

const Rules = () => {
  useEffect(() => {
    goToTop();
  }, []);

  const { t } = useTranslation();

  const WEBSITE_NAME = configs.websiteName;

  return (
    <div className="rules page-wrapper">
      <div className="rules__body">
        <div className="page-wrapper__image">
          { isMobile ?
            <img src="./images/rulesPage/rules-img-mobile.png" alt="" /> :
            <img src="./images/rulesPage/rules-img.png" alt="" />
          }
        </div>
        <h1>{t('rules')}</h1>
        {textContent.rulesText.map((item, i) => (
          <div className='rules__body__items'>
            <h3 key={i}>{t(`rulesSection.${item.title}.title`, {websiteName: WEBSITE_NAME})}</h3>
            {item.text?.map((text, i) => (
              <span className="page-wrapper__text" key={i}>
                {t(`rulesSection.${item.title}.${text}`, {websiteName: WEBSITE_NAME})}
              </span>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Rules;
