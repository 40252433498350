import React from 'react';
import { useTranslation } from 'react-i18next';
import ComingSoon from '../CommingSoon';
import './TournamentCategoryWidget.scss';
import { configs } from '../../../configs/configs';

const TournamentCategoryWidget = ({icon, text, comingSoon}) => {

  const { t } = useTranslation();
  const WEBSITE_NAME = configs.websiteName;

  return (
    <div className='tournament-category-widget-wrapper'>
      <div className='category-icon'>
        <span><i className={`icon-${icon}`} /></span>
      </div>
      <div className='category-description'>{t(`tournamentCategories.${text}`, { websiteName: WEBSITE_NAME })}</div>
      {!!comingSoon && <ComingSoon />}
    </div>
  )
}

export default TournamentCategoryWidget