export const prop = (key) => (obj) => obj?.[key];

export const classNames = (...args) => {
  const classes = [];

  for (let i = 0; i < args.length; i++) {
    const arg = args[i];

    if (arg) {
      if (typeof arg === 'object') {
        for (const key in arg) {
          if (arg[key]) {
            classes.push(key);
          }
        }
      } else {
        classes.push(arg);
      }
    }
  }

  return classes.join(' ').trim();
};

export const goToTop = () => {
  window.scrollTo({
    left: 0,
    top: 0,
  });
};

export const isObject = (obj) => {
  return obj && typeof obj === 'object' && !Array.isArray(obj) && obj !== null;
};

export const getObjKeys = (obj) => {
  if (!isObject(obj)) return;
  return Object.keys(obj);
};

export const isRequired = (value) => {
  return value === undefined || value === null || value === '';
};

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
}

export const isLettersNumbersOrUnderscores = (value) => {
  const pattern = /^[a-zA-Z0-9_]{1,}[a-zA-Z]+[0-9_]*$/;
  return value.match(pattern) ? false : true;
};
