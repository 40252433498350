import React from 'react';
import { isMobile } from 'react-device-detect';
import { classNames } from '../../../helpers/pureFunctions';
import './StepsToPlayWidget.scss';
import StepsToPlayWidgetDesktop from './StepsToPlayWidgetDesktop';
import StepsToPlayWidgetMobile from './StepsToPlayWidgetMobile';
import StepsToPlayWidgetInfo from '../../../configs/howToPlayStepsToPlayInfo.json'

const StepsToPlayWidget = () => {

  const stepsToPlayWidgetWrapperClasses = classNames('steps-to-play-widget-wrapper', {
    mobile: isMobile
  })

  return (
    <div className={stepsToPlayWidgetWrapperClasses}>
      {isMobile ?
        (
          <StepsToPlayWidgetMobile data={StepsToPlayWidgetInfo} scrollCount={1} />
        ) : (
          StepsToPlayWidgetInfo.map(info => <StepsToPlayWidgetDesktop key={info.step} data={info} />)
        )
      }
    </div>
  )
}

export default StepsToPlayWidget