import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutSideClicker } from '../../../hooks/useOutSideClicker';
import './Dropdown.scss';

const Dropdown = ({ options, property, setForm, value, callback }) => {
  const { t } = useTranslation();
  const [option, setOption] = useState(value);
  const { isShow, ref, setIsShow } = useOutSideClicker();

  useEffect(() => {
    if (value) {
      setOption(value);
    }
  }, [value]);

  const onClickHandler = (option) => {
    if (callback) {
      callback(option);
    } 
    if (setForm) {
      setForm((prev) => ({ ...prev, [property]: option }));
    }
    setOption(option);
    setIsShow(false);
  };

  return (
    <div className={`dropdown ${isShow ? 'open' : ''}`} ref={ref}>
      <div className="header" onClick={() => setIsShow((state) => !state)}>
        <span className={`drop-item ${isShow ? 'active' : ''}`} title={t(option)}>
          {t(option)}
        </span>
        <i className="icon-arrow" />
      </div>
      <div className="body">
        {options
          .filter((item) => item !== option)
          .map((option, idx) => (
            <div key={idx} onClick={onClickHandler.bind(null, option)}>
              <span className="drop-item" title={t(option)}>
                {t(option)}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Dropdown;
