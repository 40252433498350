import React from 'react';
import './TournamentTypesWidget.scss';
import { classNames } from '../../../helpers/pureFunctions';
import { useState } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import ComingSoon from '../CommingSoon';
import TournamentTypesData from '../../../configs/howToPlayPagetournamentTypesInfo.json';
import { configs } from '../../../configs/configs';

const TournamentTypesWidget = () => {

    const WEBSITE_NAME = configs.websiteName;

    const { t } = useTranslation();
    const [openItem, setOpenItem] = useState(null);

    const accordionToggleHandler = (id) => {
        openItem === id ? setOpenItem(null) : setOpenItem(id)
    }

    const tournamentTypesWidgetClasses = classNames('tournament-types-widget-wrapper', {
        mobile: isMobile,
        desktop: isDesktop
    });

    return (
        <div className={tournamentTypesWidgetClasses}>
            {TournamentTypesData.map((info, index) => (
                <div key={index} className='tournament-types-widget-container'>
                    <div className={`tournament-types-widget-container__header ${openItem === index ? 'show' : 'hide'}`} onClick={() => accordionToggleHandler(index)}>
                        <span className='tournament-types-widget-container__header--title'>
                            {t(`tournamentTypesWidget.${info.type}.title`)}
                            {info.hasCommingSoon && isDesktop && <span className='title-comming-soon'><ComingSoon/></span>}
                        </span>
                        {info.hasCommingSoon && isMobile && <span className='title-comming-soon'><ComingSoon/></span>}
                        {isMobile && (
                            <span className={`tournament-types-widget-container__header--icon ${openItem === index && 'up'}`}>
                                <i className='icon-arrow-right' />
                            </span>
                        )}
                    </div>
                    <div className={`tournament-types-widget-container__body ${openItem === index ? 'show' : 'hide'}`}>
                        <span className='main-info__item--value'>{t(`tournamentTypesWidget.${info.type}.description`, {websiteName: WEBSITE_NAME})}</span>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default TournamentTypesWidget