import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import { getObjKeys } from '../../../../helpers/pureFunctions';
import Input from '../../../common/Input';
import { getUserDetails } from '../../../../store/selectors';
import '../MyAccountMobile.scss';

const AccountDetailsMobile = () => {
  const { t } = useTranslation();
  const user = useSelector(getUserDetails);
  const [form, setForm] = useState({
    name: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    birthDate: '',
    taxCode: '',
    country: '',
    city: '',
    address: '',
    postalCode: '',
  });

  useEffect (() => {
    const tempObj = {};
    if(user.personalInfo?.firstname) tempObj.name = user.personalInfo.firstname
    if(user.personalInfo?.lastname) tempObj.lastName = user.personalInfo.lastname
    if(user.personalInfo?.email) tempObj.email = user.personalInfo.email;
    if (getObjKeys(tempObj).length) setForm(prev => ({ ...prev, ...tempObj }));
  }, [user]);

  return (
    <div className="account-details-mobile">
      <div className="my-account__section-item">
        <span className="my-account__section-name">{t('identity')}</span>
        <div className="my-account__section-body">
          <Input
            setForm={setForm}
            property="name"
            label="name"
            value={form.name}
            variant="primary"
          />
          <Input
            setForm={setForm}
            property="lastName"
            label="lastName"
            value={form.lastName}
            variant="primary"
          />
          <Input
            setForm={setForm}
            property="email"
            label="email"
            value={form.email}
            variant="primary"
          />
          <Input
            setForm={setForm}
            property="phoneNumber"
            label="phoneNumber"
            value={form.phoneNumber}
            variant="primary"
          />
          <Input
            setForm={setForm}
            property="birthDate"
            label="birthDate"
            value={form.birthDate}
            variant="primary"
          />
          <Input
            setForm={setForm}
            property="taxCode"
            label="taxCode"
            value={form.taxCode}
            variant="primary"
          />
        </div>
      </div>
      <div className="my-account__section-item">
        <span className="my-account__section-name">{t('address')}</span>
        <div className="my-account__section-body">
          <Input
            setForm={setForm}
            property="country"
            label="country"
            value={form.country}
            variant="primary"
          />
          <Input
            setForm={setForm}
            property="city"
            label="city"
            value={form.city}
            variant="primary"
          />
          <Input
            setForm={setForm}
            property="address"
            label="address"
            value={form.address}
            variant="primary"
          />
          <Input
            setForm={setForm}
            property="postalCode"
            label="postalCode"
            value={form.postalCode}
            variant="primary"
          />
        </div>
      </div>
    </div>
  );
};

export default AccountDetailsMobile;
