import React from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../../helpers/pureFunctions';
import './HeaderBannerWidget.scss';

const HeaderBannerWidget = () => {
  const { t } = useTranslation();

  const headerBannerWidgetClasses = classNames('header-banner-widget', {
    mobile: isMobile,
  });

  return <div className={headerBannerWidgetClasses}></div>;
};

export default HeaderBannerWidget;
