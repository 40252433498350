import { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { GoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { getLS } from '../../helpers/localStorage';
import { validator } from '../../helpers/validator';
import { createLoginValidator, createRegisterValidator } from '../../helpers/validators';
import { loginRequest } from '../../store/actionCreators/authentication';
import { registerRequest } from '../../store/actionCreators/register';
import { getRegisterSuccessDialogStatus, isAuthenticated } from '../../store/selectors';
import { authRequest } from '../../store/actionCreators/authentication';
import Button from '../common/Button';
import Checkbox from '../common/Checkbox';
import Input from '../common/Input';
import { classNames, goToTop } from '../../helpers/pureFunctions';
import { configs } from '../../configs/configs';
import './Auth.scss';
import { getAccountBalanceRequest } from '../../store/actionCreators/getAccountBalance';

const Auth = ({ setIsAuth }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isAuth = useSelector(isAuthenticated);
  const registerSuccessDialogStatus = useSelector(getRegisterSuccessDialogStatus);
  const history = useHistory();
  const { pathname } = useLocation();
  const isLoginPage = pathname === '/login';
  const selectedPage = getLS('page');
  const [form, setForm] = useState({
    username: '',
    password: '',
    repeatPassword: '',
    email: '',
  });
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [repeatPasswordVisibility, setRepeatPasswordVisibility] = useState(false);

  const registerSubmitHandler = () => {
    const validatorResultFunc = createRegisterValidator(form);
    const errors = validator(validatorResultFunc(), form);
    if (!errors) {

      setForm({ ...form, errors: {} });
      dispatch(registerRequest(form.username, form.password));
    } else {
      setForm({ ...form, errors });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLoginPage) {
      const validatorResultFunc = createLoginValidator();
      const errors = validator(validatorResultFunc(), form);
      if (!errors) {
        setForm({ ...form, errors: {} });
        dispatch(loginRequest(form.username, form.password));
      } else {
        setForm({ ...form, errors });
      }
    } else {
      if (isMobile) {
        if (form.firstCheck && form.secondCheck) {
          registerSubmitHandler();
          return;
        }
      }
      registerSubmitHandler();
    }
  };

  const onRoute = (route) => {
    history.push({
      pathname: route,
      breakToTopScroll: true,
    });
    setForm({ ...form, errors: {} });
    isMobile && goToTop();
  };

  const onClose = () => {
    history.push('/');
  };

  useEffect(() => {
    if (isAuth) {
      history.push(`/${selectedPage || 'tipster'}`);
    }
  }, [isAuth, selectedPage]);

  useEffect(() => {
    if (registerSuccessDialogStatus && setIsAuth) {
      setIsAuth(true);
    }
  }, [registerSuccessDialogStatus, setIsAuth]);

  const authClassNames = classNames('auth', {
    'auth--login': isLoginPage,
    'auth--register': !isLoginPage,
  });

  const authInfoSectionClassNames = classNames('auth__info-section', {
    'auth__info-section--login': isLoginPage,
    'auth__info-section--register': !isLoginPage,
  });

  // const googleLoginSuccess = (response) => {
  //   dispatch(authRequest(response.credential));
  // };

  const onChangeHandler = (value, property) => {
    setForm((prev) => ({ ...prev, [property]: value, errors: { ...prev.errors, [property]: '' } }));
  };

  return (
    <div className={authClassNames}>
      <div className='auth__content'>
        <div className='auth__sections'>
          <div className={authInfoSectionClassNames} />
          <div className='auth__form-section'>
            <i className='icon-cross auth__close-icon' onClick={onClose} />
            <div className='auth__form-section-content'>
              <span className='auth__form-title'>{isLoginPage ? t('singInToYourAccount') : t('createAnAccount')}</span>
              {/* <div className='auth__google-button-wrapper'>
                <GoogleLogin
                  onSuccess={googleLoginSuccess}
                  onError={(error) => {
                    console.log('google authentication error = ', error);
                  }}
                  useOneTap
                  size='large'
                  theme='filled_blue'
                />
              </div> */}
              {/* <div className='auth__or-wrapper'>
                <div className='auth__or-line' />
                <span className='auth__or-text'>{t('or')}</span>
                <div className='auth__or-line' />
              </div> */}
              <div className='auth__form'>
                <form>
                  <div className='auth__form--inputs-wrapper'>
                    <div className='auth__input-wrapper'>
                      <Input
                        callbackFn={onChangeHandler}
                        property='username'
                        value={form.username}
                        variant='auth'
                        error={form.errors?.username}
                        label={'username'}
                        required
                      />
                    </div>
                    {!isLoginPage && (
                      <div className='auth__input-wrapper'>
                        <Input
                          callbackFn={onChangeHandler}
                          property='email'
                          value={form.email}
                          variant='auth'
                          error={form.errors?.email}
                          label={'email'}
                          required
                        />
                      </div>
                    )}
                    <div className='auth__input-wrapper'>
                      <Input
                        callbackFn={onChangeHandler}
                        property='password'
                        value={form.password}
                        variant='auth'
                        type={passwordVisibility ? 'text' : 'password'}
                        error={form.errors?.password}
                        label={'password'}
                        required
                      />
                      <span
                        className='password-visibility-icon'
                        onClick={() => setPasswordVisibility(!passwordVisibility)}>
                        <i className={passwordVisibility ? 'icon-visibility' : 'icon-visibility-off'} />
                      </span>
                    </div>
                    {!isLoginPage && (
                      <div className='auth__input-wrapper'>
                        <Input
                          callbackFn={onChangeHandler}
                          property='repeatPassword'
                          value={form.repeatPassword}
                          variant='auth'
                          type={repeatPasswordVisibility ? 'text' : 'password'}
                          error={form.errors?.repeatPassword}
                          label={'repeatPassword'}
                          required
                        />
                        <span
                          className='password-visibility-icon'
                          onClick={() => setRepeatPasswordVisibility(!repeatPasswordVisibility)}>
                          <i className={repeatPasswordVisibility ? 'icon-visibility' : 'icon-visibility-off'} />
                        </span>
                      </div>
                    )}
                  </div>
                  {/* {isLoginPage && (
                    <div className='auth__remember_me_wrapper'>
                      <Checkbox
                        property='rememberMe'
                        setForm={setForm}
                        label='rememberMe'
                        state={form.rememberMe}
                        className='auth__checkbox'
                      />
                    </div>
                  )} */}
                  <div className='auth__button-wrapper'>
                    <Button
                      // disabled={isLoginPage ? false : !form.firstCheck || !form.secondCheck}
                      onClick={handleSubmit}
                      variant='auth'>
                      {isLoginPage ? t('logIn') : t('signUp')}
                    </Button>
                  </div>
                </form>
                {isLoginPage && (
                  <div className='auth__forgot-password-wrapper'>
                    {/* <Link to='/' className='auth__forgot-password-wrapper--link'>{t('forgotPassword')}?</Link> */}
                    <span className='auth__forgot-password-wrapper--link'>{t('forgotPassword')}?</span>
                  </div>
                )}
                <div className='auth__route-to-auth-wrapper'>
                  <span className='auth__route-to-auth-label'>
                    {isLoginPage ? t('notAMemberYet') : t('alreadyHaveAnAccount')}
                  </span>
                  <span
                    onClick={onRoute.bind(null, isLoginPage ? '/register' : '/login')}
                    className='auth__route-to-auth-value'>
                    {isLoginPage ? t('signUp') : t('signIn')}
                  </span>
                </div>
                <div className='auth__input-wrapper'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
