import Dropdown from "../../../common/Dropdown";
import Presets from "../../../common/Presets";
import './TransactionHistoryFilters.scss';

const TransactionHistoryFilters = ({ setForm, form }) => {
  return (
    <div className="transaction-history-filters">
      <div className="transaction-history-filters__dropdown-wrapper">
        <Dropdown
          property="type"
          setForm={setForm}
          value={form.type}
          options={['all', 'depositWithdrawals', 'gameTransfers']}
        />
      </div>
      <div className="transaction-history-filters__presets-wrapper">
        <Presets setForm={setForm} form={form} />
      </div>
    </div>
  );
};

export default TransactionHistoryFilters;
