import { useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import io from 'socket.io-client';
import { getToken, getUserDetails } from '../store/selectors';
import { successMessages } from '../store/actionCreators/messages';
import settings from '../configs/settings';

const socketUrl = settings.MESSAGES_URL;

const useMessagesSocket = () => {
  const dispatch = useDispatch();
  const socket = useRef(null);
  const token = useSelector(getToken);
  const user = useSelector(getUserDetails);
  const skinId = settings.skinId;
  let id = user ? user.id : null;
  let username = user ? user.username : null;

  const onNewMessage = (message) => {
    if (!message) return;
    dispatch(successMessages(message || []));
  };

  useEffect(() => {
    if (!socketUrl || !id || !username || !token) return;
    socket.current = io(socketUrl, {
      query: { username, id, token },
    });
    socket.current.on('newMessage', (message) => onNewMessage(message));

    return () => {
      if (socket.current) socket.current.disconnect(true);
      socket.current = null;
    };
  }, [socketUrl, id, username, token]);

  useEffect(() => {
    if (skinId && socket.current) {
      socket.current.emit('getMessages', { skinId }, (err, params) => {
        if (err) {
          return;
        }
        dispatch(successMessages(params.payload.messages[id] || []));
      });
    }
  }, [socket.current, skinId]);

  return useCallback((type, params) => {
    if (socket.current) socket.current.emit(type, params);
  }, []);
};

export default useMessagesSocket;
