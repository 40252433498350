import React from 'react';
import { useTranslation } from 'react-i18next';
import './StepsToPlayWidgetDesktop.scss';
import { configs } from '../../../../configs/configs';

const StepsToPlayWidgetDesktop = ({data}) => {
    const { t } = useTranslation();
    const WEBSITE_NAME = configs.websiteName;
    return (
        <div className='steps-to-play-widget-desktop'>
            <div className="steps-to-play-widget-desktop__left-side">
                <div className="left-side__step-count">{t('step')} {data.step}</div>
                <div className="left-side__title">{t(`StepsToPlayWidget.${data.title}`)}</div>
                <div className="left-side__description">
                    {data.description.map(el => <p>{t(`StepsToPlayWidget.${data.stepNumber}.${el}`, {websiteName: WEBSITE_NAME})}</p>)}
                </div>
            </div>
            <div className="steps-to-play-widget-desktop__right-side">
                <img src={`/images/howToPlayPage/step${data.img}-desktop.png`} alt="" />
            </div>
        </div>
    )
}

export default StepsToPlayWidgetDesktop