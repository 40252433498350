import React, { useEffect } from 'react';
import { goToTop } from '../../helpers/pureFunctions';
import './News.scss';

const News = () => {
  useEffect(() => {
    goToTop();
  }, []);

  return <div className="news page-wrapper">Coming soon...</div>;
};

export default News;
