import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { goToTop } from '../../helpers/pureFunctions';
import Auth from '../Auth';

const Login = () => {
  const location = useLocation();

  useEffect(() => {
    if (!location.breakToTopScroll) {
      goToTop();
    }
  }, [location?.breakToTopScroll]);

  return (
    <Auth />
  );
};

export default Login;
