import React from 'react';
import Button from '../common/Button';
import { getLS } from '../../helpers/localStorage';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { logout } from '../../store/actionCreators/authentication';
import './Logout.scss';

const LOGOUT_ICON_VARIANTS = ['logout-mobile', 'logout-desktop'];

const Logout = ({ variant }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const prevPage = getLS('page');

  const handleClick = () => {
    dispatch(logout());
    window?.Tipster?.logout(4);
    history.push(`/${prevPage ? prevPage : 'home'}`);
  };

  return (
    <>
      <Button icon={LOGOUT_ICON_VARIANTS.includes(variant) ? 'logout' : ''} variant={variant || 'error'} onClick={handleClick}>
        {t('logout')}
      </Button>
    </>
  );
};

export default Logout;
