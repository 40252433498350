import { useTranslation } from 'react-i18next';
import Navigation from '../Navigation';
import './BurgerMenu.scss';

const BurgerMenu = ({ isOpenBurgerMenu, handleClickBurgerMenu }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={`menu-btn ${isOpenBurgerMenu ? 'active' : ''}`}></div>
      <div className='menu-icon' onClick={handleClickBurgerMenu}>
        <span className='navicon'></span>
        <span className='menu-icon__name'>{t('menu')}</span>
      </div>
      <div className='menu-burger'>
        <Navigation closeBurger={handleClickBurgerMenu} />
      </div>
    </>
  );
};

export default BurgerMenu;
