import { useState, useEffect, useRef } from 'react';

export const useOutSideClicker = (initial) => {
  const [isShow, setIsShow] = useState(initial);
  const ref = useRef(null);

  const handleClickOutSide = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutSide, true);

    return () => {
      document.removeEventListener('click', handleClickOutSide, true);
    };
  }, []);

  return { isShow, ref, setIsShow };
};
