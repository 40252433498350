import types from '../actionTypes';

export const transactionsRequest = (form) => ({
  type: types.GET_TRANSACTIONS_REQUEST,
  form,
});

export const transactionsSuccess = (data) => ({
  type: types.GET_TRANSACTIONS_SUCCESS,
  data,
});

export const transactionsFailure = (e) => ({
  type: types.GET_TRANSACTIONS_FAILURE,
  exception: e,
});

export const transactionsReset = () => ({
  type: types.GET_TRANSACTIONS_RESET,
});
