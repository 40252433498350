import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { classNames } from "../../helpers/pureFunctions";
import moment from "moment-timezone";
import "./Tripser.scss";
import { isMobile } from "react-device-detect";
import settings from "../../configs/settings";
import i18n from 'i18next';

const Tripser = ({ onTipsterInitialized }) => {
  const [height, setHeight] = useState();
  const timeZoneAbbreviation = moment.tz(moment.tz.guess()).format('z');
  console.log(timeZoneAbbreviation);

  const handleFrameMessages = (event) => {
    if (event?.data?.includes?.('height')) {
      console.log('cmiopcion', JSON.parse(event.data)?.eventData)
      const height = JSON.parse(event.data)?.eventData?.height;
      setHeight(height);
    }
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = settings?.TIPSTER_SDK;
    script.onload = () => {
      window.Tipster.init(4, i18n.language, 'EUR');
      window?.Tipster?.logout(4);
      if (typeof onTipsterInitialized === 'function') {
        onTipsterInitialized();
      }
    };
    document.body.appendChild(script);
    window.addEventListener('message', (e) => handleFrameMessages(e));
  }, []);



  return createPortal(
    <>
      <div style={{ height: location.pathname === '/tipster' ? isMobile ? '100%' : height : 0 }} className={classNames('', { tipster: location.pathname === '/tipster', mobile: isMobile })} id="tipsterPluginContainer"></div>
    </>,
    document.body
  );
};

export default Tripser;