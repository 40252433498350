import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import types from '../actionTypes';
import settings from '../../configs/settings';
import { emptyLS, getLS } from '../../helpers/localStorage';
import { loginReset, logout } from '../actionCreators/authentication';

const GET = 'GET';
const POST = 'POST';
const PATCH = 'PATCH';

export const LOGIN_REQUEST = 1;

export default function* callApi(url, path, method, options, headers, flags = 0) {
  const loginRequest = flags && LOGIN_REQUEST;

  let token = null;

  if (!loginRequest) {
    const data = getLS('user');
    if (!data) {
      yield put(loginReset());
      return;
    }

    while (!token) {
      token = getLS('token');
      if (!token) yield take(types.LOGIN_SUCCESS);
    }
  }

  const to = `${url}${path}`;
  const axiosConfig = { method, url: to };
  const params = { ...options };

  const hs = headers || {};

  if (params.token || token) {
    hs['x-access-token'] = params.token || token;
    delete params.token;
  }

  axiosConfig.headers = hs;

  if (options) axiosConfig[method === 'GET' ? 'params' : 'data'] = params;

  const result = yield call(axios, axiosConfig);

  if (!result.data.success && result.data.success?.message.key === 'verification_expired') {
    emptyLS();
    yield put(logout());
  }
  return result;
}

export function* apiGet(to, params, headers, flags) {
  return yield call(callApi, settings.API_URL, to, GET, params, headers, flags);
}

export function* accountingApiGet(to, params, headers, flags) {
  return yield call(callApi, settings.ACCOUNTING_API, to, GET, params, headers, flags);
}

export function* apiPost(to, params, headers, flags) {
  return yield call(callApi, settings.ACCOUNTING_API, to, POST, params, headers, flags);
}
export function* apiPatchMessaging(to, params, headers, flags) {
  return yield call(callApi, settings.API_MESSAGING_URL, to, PATCH, params, headers, flags);
}

export function* callRemoteApi(url, method, options, headers) {
  try {
    const response = yield call(createApiRequest, url, method, options, headers || {}, 'remote');
    return response;
  } catch (error) {
    yield fork(processApiError, error);
  }
}
