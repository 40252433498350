import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Table from '../../common/Table';
import FPP from '../../FPP';
import { getTransactions, isTransactionsPending } from '../../../store/selectors';
import { isMobile } from 'react-device-detect';

const getHedaers = (type) => {
  const headers = [{ label: 'date' }, { label: 'amount' }, { label: 'status' }];
  if (type === 'depositWithdrawals') {
    headers.unshift({ label: 'type' })
  }
  if (type === 'gameTransfers' || type === 'all') {
    headers.unshift({ label: 'extraInfo' });
  }
  return headers;
};

const getRows = (row, type) => {
  const rows = [moment(row.dateUpdated).format('DD/MM/YY'), <FPP size="sm" amount={row.type.includes('Transfer') ? row.amount : row.type === 'buyin' ? -row.amount : row.amount}  />, row.status];
  if (type === 'depositWithdrawals') {
    rows.unshift(row.subType)
  }
  if (type === 'gameTransfers' || type === 'all') {
    rows.unshift(row.type.includes('Transfer') ? row.subType : '--');
  }
  return rows;
}

const DEAFULT_VISIBLE_ITEMS_COUNT = 2;


const TransactionHistoryTable = ({ type }) => {
  const { t } = useTranslation();
  const transactions = useSelector(getTransactions);
  const pending = useSelector(isTransactionsPending);
  const headers = getHedaers(type);
  const [visibleItemsCount, setVisibleItemsCount] = useState(DEAFULT_VISIBLE_ITEMS_COUNT);
  const [visibleTransactions, setVisibleTransactions] = useState(null);

  const renderRow = (row) => getRows(row, type);

  useEffect(() => {
  if (transactions?.length && !visibleTransactions) {
    setVisibleTransactions(transactions?.slice(0, visibleItemsCount));
  }
  }, [transactions, visibleTransactions, visibleItemsCount]);


  const handleClick = () => {
    setVisibleItemsCount(prev => Math.min(prev + DEAFULT_VISIBLE_ITEMS_COUNT, transactions?.length));
    setVisibleTransactions(transactions?.slice(0, Math.min(visibleItemsCount + DEAFULT_VISIBLE_ITEMS_COUNT, transactions?.length)));
  };

  return (
    <>
      {pending && <div>Loading...</div>}
      {visibleTransactions?.length > 0 && (
        <Table
          headers={headers}
          data={visibleTransactions}
          rowData={renderRow}
        />
      )}
      {(isMobile && transactions?.length && visibleItemsCount < transactions?.length) && <button onClick={handleClick} className='transaction-history__button-show-more'>{t('showMore')}</button>}
    </>
  );
};

export default TransactionHistoryTable;
