const actionTypes = {
  AUTH_REQUEST: 'AUTH_REQUEST',
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  SOME_ACTION_TYPE_REQUEST: 'SOME_ACTION_TYPE_REQUEST',
  SOME_ACTION_TYPE_SUCCESS: 'SOME_ACTION_TYPE_SUCCESS',
  SOME_ACTION_TYPE_FAILURE: 'SOME_ACTION_TYPE_FAILURE',
  LANGUAGE_CHANGE: 'LANGUAGE_CHANGE',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGIN_RESET: 'LOGIN_RESET',
  LOGOUT: 'LOGOUT',
  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_SUCCESS_RESET: 'REGISTER_SUCCESS_RESET',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',
  GET_ACCOUNT_BALANCE_REQUEST: 'GET_ACCOUNT_BALANCE_REQUEST',
  GET_ACCOUNT_BALANCE_SUCCESS: 'GET_ACCOUNT_BALANCE_SUCCESS',
  GET_ACCOUNT_BALANCE_FAILURE: 'GET_ACCOUNT_BALANCE_FAILURE',
  GET_MESSAGES_SUCCESS: 'GET_MESSAGES_SUCCESS',
  READ_MESSAGE_REQUEST: 'READ_MESSAGE_REQUEST',
  READ_MESSAGE_SUCCESS: 'READ_MESSAGE_SUCCESS',
  OPEN_DIALOG: 'OPEN_DIALOG',
  CLOSE_DIALOG: 'CLOSE_DIALOG',
  GET_TRANSACTIONS_REQUEST: "GET_TRANSACTIONS_REQUEST",
  GET_TRANSACTIONS_SUCCESS: "GET_TRANSACTIONS_SUCCESS",
  GET_TRANSACTIONS_FAILURE: "GET_TRANSACTIONS_FAILURE",
  GET_TRANSACTIONS_RESET: "GET_ACCOUNT_BALANCE_RESET",
};

export default actionTypes;
