const apiRoutes = {
  GOOGLE_AUTH: '/api_v2/googleAuth',
  LOGIN: '/api_v2/authenticatePlayer',
  REGISTER: '/api_v2/registerPlayer',
  GET_CASINO_TOURNAMENTS: '/casinoTournament/getCasinoTournamentInstances',
  getAccountBalance: (id) => `/api/account/${id}/balance`,
  READ_MESSAGE: '/subscribtion',
  GET_TRANSACTIONS: '/api/transaction'
};

export default apiRoutes;
