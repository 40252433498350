import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { classNames, goToTop } from '../../helpers/pureFunctions';
import { setLS } from '../../helpers/localStorage';
import './Home.scss';
import { getToken } from '../../store/selectors';
import { dynamicTexts } from '../../configs/dynamicTexts';

const Home = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const token = useSelector(getToken);
  const { pathname } = useLocation();
  const showContent = pathname === '/tipster';
  useEffect(() => {
    setLS('page', 'home');
  });

  useEffect(() => {
    goToTop();
  }, []);

  const homePageClasses = classNames('home-page', {
    mobile: isMobile,
  });

  const onRouteHandler = () => token ? history.push('/tipster') : history.push('/register');

  return (
    !showContent ? <div className={homePageClasses}>
      <div className='home-page__left-side'>
        <div className="home-page__left-side-content">
          <div className="home-page__greeting-content">
            {!isMobile && 
            <>
              <p className='home-page__greeting-content--title' dangerouslySetInnerHTML={{ __html: t('homePage.joinForFun') }}></p>
              <p className='home-page__greeting-content--text'>{t(`homePage.${dynamicTexts.home_welcome.description}`)}</p>
            </>
            }
            <button onClick={onRouteHandler} className='home-page__greeting-content--sign-in-button'>{ token ? t('goToTournaments') :  t('homePage.signUpShort')}</button>
          </div>
        </div>
      </div>
      {
        !isMobile &&
        <>
          <div className="home-page__info-panel">
            <div className="home-page__info-panel-content">
              <div className="home-page__info-panel-list">
                {['register', 'play', 'win'].map((item, index) => (
                  <div key={item} className="home-page__info-panel-item">
                    <div className={`home-page__info-panel-item-image home-page__info-panel-item-image--${index + 1}`} />
                    <div className="home-page__info-panel-item-texts">
                      <span className="home-page__info-panel-item-title">{t(`homePageBannerInfoPanel.${item}.title`)}</span>
                      <span className="home-page__info-panel-item-description">{t(`homePageBannerInfoPanel.${item}.description`, {description: dynamicTexts.home_infoPanel[item]?.description})}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      }
    </div> : null
  );
};

export default Home;
