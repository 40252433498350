// page_section

export const dynamicTexts = {
    home_welcome: {
        description: 'winHugePrizesEveryDay'
    },
    home_infoPanel: {
        win: {
            description: "amazing prize"
        },
    }
}