import React, { useState, useEffect } from 'react';
import { getUserDetails, getFPPWallet } from '../../store/selectors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import stepperJSON from './stepper.json';
import Logout from '../Logout';
import './myAccount.scss';
import FPP from '../FPP';
import { Views } from './Views';
import { goToTop } from '../../helpers/pureFunctions';
import Input from '../common/Input';
import { useFormatCurrency } from '../../hooks/useCorrency';

function MyAccountDesktop() {
  const { t } = useTranslation();
  const { playerInfo } = stepperJSON;
  const [selectedSection, setSelectedSection] = useState(playerInfo[0].title);
  const formatCurrency = useFormatCurrency();
  const user = useSelector(getUserDetails);

  const userData = useSelector(getUserDetails);
  const FPPWallet = useSelector(getFPPWallet);

  useEffect(() => {
    goToTop();
  }, []);

  const handleClick = (section) => {
    if(section.disabled) return;
    setSelectedSection(section.title);
  };
  const View = Views[selectedSection];

  return (
    <div className='my-account'>
      <div className='my-account__player'>
        <div className="my-account__player-info-wrapper">
          <span className='my-account__player-name'>{userData?.nickname}</span>
          <div className="my-account__player-info">
            <span className='my-account__player-id'>{`${t('id')}:  ${userData?.id}`}</span>
            <div className='my-account__player-balance'>
              <span className="my-account__player-balance-label">{`${t('balance')}:`}</span>
              <div className="my-account__player-balance-value-wrapper">
                <span style={{ color: '#78be4b', fontWeight: 700 }}>{formatCurrency(user?.wallets?.[0]?.balance, user?.wallets?.[0]?.currency)}</span>
              </div>
            </div>
          </div>
          <div className="account-details__section-input-wrapper">
            <Input
              property="email"
              value={userData.personalInfo?.email}
              variant="primary"
              disabled={true}
            />
          </div>
        </div>
        <div className="my-account__nav-list">
          {playerInfo.map((info) => (
            <div key={info.id} className={`my-account__nav-item ${info.title === selectedSection ? 'my-account__nav-item--active' : ''} ${info.disabled ? 'my-account__nav-item--disabled' : ''}`} onClick={() => handleClick(info)}>
              <div className="my-account__nav-item-icon-wrapper">
                <i className={`icon-${info.icon} my-account__nav-item-icon`} />
              </div>
              <span className="my-account__nav-item-text">{t(info.title)}</span>
            </div>
          ))}
        </div>
        <div className="my-account__logout-wrapper">
          <Logout variant="logout-desktop" />
        </div>
      </div>
      <div className="my-account__view">
        <View />
      </div>
    </div>
  );
}

export default MyAccountDesktop;
