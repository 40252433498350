import { all, call } from 'redux-saga/effects';
import watchAuthentication, { watchLogout } from './authentication';
import watchExternalAuthentication from './externalAuthentication';
import watchRegister from './register';
import getAccountBalanceRequests from './getAccountBalance';
import watchReadMessage from './messages';
import transactionsRequests from './transactions';

function* rootSaga() {
  yield all([
    call(watchAuthentication),
    call(watchRegister),
    call(getAccountBalanceRequests),
    call(watchLogout),
    call(watchReadMessage),
    call(transactionsRequests),
    call(watchExternalAuthentication),
  ]);
}

export default rootSaga;
