import { take, delay, fork, call, put, cancel } from 'redux-saga/effects';
import types from '../actionTypes';
import { apiPost, LOGIN_REQUEST } from './callApi';
import { registerSuccess, registerFailure } from '../actionCreators/register';
import { loginRequest } from '../actionCreators/authentication';
import apiRoutes from '../../constants/apiRoutes';
import settings from '../../configs/settings';

function* makeRegister({ username, password }) {
  try {
    const params = {
      allowSimpleRegistration: true,
      username: username,
      password,
      skinId: settings.skinId,
      parentId: settings.skinId,
    };
    console.log(params)
    const response = yield call(apiPost, apiRoutes.REGISTER, params, {}, LOGIN_REQUEST);

    if (response.data.success) {
      yield put(registerSuccess(response.data.message));
      yield put(loginRequest(username, password));
    } else {
      console.log('REGISTER FAILURE');
    }
  } catch (error) {
    try {
      alert(JSON.parse(error?.response?.data?.message?.error)?.message);
    } catch (e) {
      alert(error?.response?.data?.message?.key);
    }
  }
}

export default function* watchRegister() {
  let forked = null;
  while (true) {
    const action = yield take(types.REGISTER_REQUEST);
    console.log(action);
    if (forked) yield cancel(forked);
    yield fork(makeRegister, action);
    yield delay(2000);
  }
}
