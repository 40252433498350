import React from "react";
import "./YoutubeVideoWidget.scss";

const YoutubeVideoWidget = ({ src, width = "660", height = "415" }) => {
  return (
    <div className="youtube-widget-wrapper">
      <iframe
        width={width}
        height={height}
        src={src}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  );
};

export default YoutubeVideoWidget;
