import { useTranslation } from 'react-i18next';
import { classNames } from '../../../helpers/pureFunctions';
import './Input.scss';

// {property, setFilterBy} = {search filed name, sets search result}  /optional/
//
const Input = ({ variant, type, value, disabled, label, callbackFn, placeholder, property, setForm, radioOptons, error, required, others }) => {
  const { t } = useTranslation();

  const inputClassName = classNames('input', {
    [`input--error`]: !!error,
  });

  const onChangeValue = (e) => {
    if (callbackFn) {
      callbackFn(e.target.value, e.target.id);
    }
    if (setForm && property) {
      setForm(prev => ({ ...prev, [property]: e.target.value }));
    }
  };

  return radioOptons?.length > 0 ? (
    <div className="radio-inputs-wrapper">
      {radioOptons?.map(({val, label}) => (
        <div className="radio-input-item" key={val}>
          <label htmlFor={val}>{t(label)}</label>
          <div className="radio-input">
            <input
              id={val}
              type="radio"
              name={label}
              checked={value === val}
              value={val}
              placeholder={t(placeholder) || ''}
              onChange={onChangeValue}
            />
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className={`input-wrapper input-wrapper--${variant}`}>
      {label &&  <label htmlFor={property}>{required && <span className='required'>*</span> }{t(label)}</label>}
      <input
        id={property}
        type={type || 'text'}
        className={inputClassName}
        placeholder={t(placeholder)}
        value={value || ''}
        onChange={onChangeValue}
        disabled={disabled}
        {...others}
      />
      {error && <span className='input__error-text'>{error}</span>}
    </div>
  );
};

export default Input;