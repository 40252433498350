import { getObjKeys } from "./pureFunctions";

export const validator = (config, form) => {
  const errors = {};
  const formKeys = getObjKeys(form);
  formKeys.forEach((key) => {
    if (config[key]) {
      const validatorKeys = getObjKeys(config[key]);
      validatorKeys.forEach((validatorKey) => {
        if (config[key][validatorKey].check) {
          if (config[key][validatorKey].check(form[key])) {
            errors[key] = config[key][validatorKey]?.errorMessage;
          }
        }
      });
    }
  });
  return getObjKeys(errors).length > 0 ? errors : null;
};
