import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { goToTop } from '../../helpers/pureFunctions';
import Auth from '../Auth';

const Register = () => {
  const [isAuth, setIsAuth] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (!location.breakToTopScroll) {
      goToTop();
    }
  }, [location?.breakToTopScroll]);

  return <Auth setIsAuth={setIsAuth} />;
};

export default Register;
