import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import Input from '../../common/Input';
import Button from '../../common/Button';
import { getUserDetails } from '../../../store/selectors';
import './AccountDetails.scss';
import { getObjKeys } from '../../../helpers/pureFunctions';

const AccountDetails = () => {
const { t } = useTranslation();
const user = useSelector(getUserDetails);
const [form, setForm] = useState({
    name: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    birthDate: '',
    taxCode: '',
    country: '',
    city: '',
    address: '',
    postalCode: '',
  });

useEffect(() => {
  const tempObj = {};
  if(user.personalInfo?.firstname) tempObj.name = user.personalInfo.firstname
  if(user.personalInfo?.lastname) tempObj.lastName = user.personalInfo.lastname
  if(user.personalInfo?.email) tempObj.email = user.personalInfo.email;
  if (getObjKeys(tempObj).length) setForm(prev => ({ ...prev, ...tempObj }));
}, [user]);


  return (
    <div className="account-details">
      <div className="account-details__content">
        <div className='account-details__sections'>
          <div className="account-details__section">
            <span className="account-details__section-title">{t('identity')}</span>
            <div className="account-details__section-form">
              <div className="account-details__section-form-row">
                <div className="account-details__section-input-wrapper">
                  <Input
                    setForm={setForm}
                    property="name"
                    label="name"
                    value={form.name}
                    variant="primary"
                    required
                  />
                </div>
                <div className="account-details__section-input-wrapper">
                  <Input
                    setForm={setForm}
                    property="lastName"
                    label="lastName"
                    value={form.lastName}
                    variant="primary"
                    required
                  />
                </div>
              </div>
              <div className="account-details__section-form-row">
                <div className="account-details__section-input-wrapper">
                  <Input
                    setForm={setForm}
                    property="email"
                    label="email"
                    value={form.email}
                    variant="primary"
                    required
                  />
                </div>
                <div className="account-details__section-input-wrapper">
                  <Input
                    setForm={setForm}
                    property="phoneNumber"
                    label="phoneNumber"
                    value={form.phoneNumber}
                    variant="primary"
                    required
                  />
                </div>
              </div>
              <div className="account-details__section-form-row">
                <div className="account-details__section-input-wrapper">
                  <Input
                    setForm={setForm}
                    property="birthDate"
                    label="birthDate"
                    value={form.birthDate}
                    variant="primary"
                    required
                  />
                </div>
                <div className="account-details__section-input-wrapper">
                  <Input
                    setForm={setForm}
                    property="taxCode"
                    label="taxCode"
                    value={form.taxCode}
                    variant="primary"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="account-details__section">
            <span className="account-details__section-title">{t('address')}</span>
            <div className="account-details__section-form">
              <div className="account-details__section-form-row">
                <div className="account-details__section-input-wrapper">
                  <Input
                    setForm={setForm}
                    property="country"
                    label="country"
                    value={form.country}
                    variant="primary"
                    required
                  />
                </div>
                <div className="account-details__section-input-wrapper">
                  <Input
                    setForm={setForm}
                    property="city"
                    label="city"
                    value={form.city}
                    variant="primary"
                    required
                  />
                </div>
              </div>
              <div className="account-details__section-form-row">
                <div className="account-details__section-input-wrapper">
                  <Input
                    setForm={setForm}
                    property="address"
                    label="address"
                    value={form.address}
                    variant="primary"
                    required
                  />
                </div>
                <div className="account-details__section-input-wrapper">
                  <Input
                    setForm={setForm}
                    property="postalCode"
                    label="postalCode"
                    value={form.postalCode}
                    variant="primary"
                    placeholder="xxxx"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='account-details__submit-wrapper'>
          <Button variant="primary-rounded">
            {t('updateAccount')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
