import React, { useEffect, useState } from 'react';
import './UserInfo.scss';
import warning from './warning.png';
import check from './check.png';
import user from './user.png';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getFPPWallet, getUserDetails, isBalancePending } from '../../store/selectors';
import { getAccountBalanceRequest } from '../../store/actionCreators/getAccountBalance';
import { classNames } from '../../helpers/pureFunctions';
import FPP from '../FPP';
import { useTranslation } from 'react-i18next';
import { useFormatCurrency } from '../../hooks/useCorrency';

const UserInfo = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const pending = useSelector(isBalancePending);
  const history = useHistory();
  const FPPWalet = useSelector(getFPPWallet);
  const user = useSelector(getUserDetails);
  const [hasAnimation, setAnimation] = useState(false);
  const formatCurrency = useFormatCurrency();
  const onRedirectToMyAccount = () => {
    history.push('/my-account');
  };

  const getBalance = () => {
    dispatch(getAccountBalanceRequest());
    setAnimation(true);
  };

  const handleMessage = (event) => {
    if (event.data.name === 'balanceUpdate') {
      getBalance();
    }
  };

  useEffect(() => {
    let timer;
    if (hasAnimation) {
      timer = setTimeout(() => {
        setAnimation(false);
      }, 400);
    }
    return () => clearTimeout(timer);
  }, [hasAnimation]);

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const userInfoDetailedCircleClassnames = classNames('userInfo__detailed__circle', 'icon-loop', {
    'userInfo__detailed__circle--spinner': hasAnimation || pending,
  });

  return (
    <div className='userInfo'>
      <div className='userInfo__detailed'>
        {/* <i className={userInfoDetailedCircleClassnames} onClick={getBalance} /> */}
        <div className='userInfo__button userInfo__balance-wrapper'>
          <span className='balance__text'>{t('balance')}:</span>
          <span style={{ color: '#78be4b', fontWeight: 700 }}>{formatCurrency(user?.wallets?.[0]?.balance, user?.wallets?.[0]?.currency)}</span>
        </div>
        <div className='userInfo__button userInfo__detailed__user' onClick={onRedirectToMyAccount}>
          <i className='icon-user' />
          <i className='icon-arrow-right' />
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
