import { useState, useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import Logo from '../common/Logo';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../helpers/pureFunctions';
import gitVersion from '../../_git_commit';
import { configs } from '../../configs/configs';
import './Footer.scss';

const Footer = ({ fixedFooterRoutes }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();
  const footerClassName = useRef('');
  const pathnameCheck = fixedFooterRoutes.includes(pathname);
  const gitInfo = gitVersion.logMessage ? gitVersion.logMessage : 'xxx';

  const COPYRIGHT = configs.websiteNameDomainStarting;
  const DOMAIN_ENDING = configs.websiteNameDomainEnding;

  const menuLinks = [
    { id: 1, title: 'tipster', link: '/tipster' },
    { id: 2, title: 'guide', link: '/guide' },
    { id: 3, title: 'news', link: '/news' },
    { id: 4, title: 'rules', link: '/rules' },
  ];

  const handleChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
  };

  useEffect(() => {
    if (!pathnameCheck) {
      setIsOpen(false);
      footerClassName.current = '';
    }
  }, [pathname, footerClassName.current]);

  const onToggleHandler = () => {
    if (!footerClassName.current) {
      footerClassName.current = 'footer-wrapper--fixed-active';
    }
    setIsOpen(!isOpen);
  };

  const footerClassNames = classNames(`footer-wrapper ${footerClassName.current}`, {
    'footer-wrapper--fixed': pathnameCheck,
  });

  const onLinkToHandler = (link, e) => {
    e.preventDefault();
    if (!link) return;
    window.open(link, '_blank');
  };

  return (
    <>
      {pathnameCheck && isOpen && <div className='footer-overlay' />}
      <div
        className={footerClassNames}
        style={{ transform: `translateY(${pathnameCheck ? (isOpen ? '0' : '100%') : '0'})` }}>
        <div className='footer__footer'>
          {!isMobile && (
            <>
              <div className='footer-logo'>
                <Logo />
              </div>
              <div className='footer__footer-copyright'>info@gameofslots.com</div>
            </>
          )}
          <span className='footer__git-version'>{`${t('version')}: ${gitInfo}`}</span>
        </div>
      </div>
    </>
  );
};

export default Footer;
