import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import './Table.scss';

const Table = ({
  headers,
  data,
  rowData,
  onSort,
  sort,
}) => {
  const { t } = useTranslation('common');

  const handleSorting = (e) => {
    const { sortby } = e.currentTarget.dataset;
    if (!sortby) return '';
    onSort({ sortBy: sortby, order: sort?.order === 1 ? -1 : 1 });
  };

  return (
    <div className="tableFixHead">
      <table>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th
                data-sortby={header.sortBy}
                className={`table-header-cell ${
                  header.uppercase ? 'uppercase' : ''
                }`}
                key={index}
                onClick={handleSorting}
              >
                <div className="label-sort-wrapper">
                  {typeof header.label === 'string' ? (
                    <span>{t(header.label)}</span>
                  ) : (
                    header.label
                  )}
                  {header?.sortBy && (
                    <div className="sort-i">
                      {sort?.order === 1 ? (
                        <i className="icon-angle-up" />
                      ) : (
                        <i className="icon-angle-down" />
                      )}
                    </div>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 &&
            data.map((item, index) => (
              <tr key={index}>
                {rowData(item, index).map((row, idx) => (
                  <td key={row?.id || idx}>{row}</td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default memo(Table);
