import { useCallback } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DialogTemplate from '../../common/Modal';
import { closeDialog } from '../../../store/actionCreators/dialogManager';

const SpinAndGoStarted = ({ dialogType, tournamentId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const onConfirmHandler = useCallback(() => {
    const casinoTournamentIframe = document.querySelector('.iframe-wrapper iframe');
    if (casinoTournamentIframe) {
      history.push('/tipster');
      casinoTournamentIframe?.contentWindow.postMessage({ type: 'openSipinAndGo', tournamentId }, '*');
    }
    dispatch(closeDialog(dialogType));
  }, []);

  return (
    <DialogTemplate
      className="spin-and-go-started-pop-up"
      dialogName="spinAndGoStartedDialog"
      onConfirm={onConfirmHandler}
      confirmText={t('join')}
      titleParams={{
        tournamentId,
      }}
    />
  );
};

export default SpinAndGoStarted;
