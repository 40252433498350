import { fail, getInitial, reset, wait } from '../helpers/redux';
import types from './actionTypes';

const dialogManagerInitial = {
  dialogs: {},
};

export const dialogManager = (state = dialogManagerInitial, action) => {
  switch (action.type) {
    case types.OPEN_DIALOG:
      document.body.classList.add("modal-open");
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          [action.payload.dialogType]: action.payload,
        },
      };
    case types.CLOSE_DIALOG:
      document.body.classList.remove("modal-open");
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          [action.payload]: undefined,
        },
      };
    default:
      return state;
  }
};

export const flags = (state = {}, action) => {
  switch (action.type) {
    case types.REGISTER_SUCCESS:
      return { ...state, registerSuccessDialogStatus: true };
    case types.REGISTER_SUCCESS_RESET:
      return { ...state, registerSuccessDialogStatus: false };
    default:
      return state;
  }
};

const authInitial = getInitial({
  token: null,
  authenticated: null,
  user: null,
});

export const auth = (state = authInitial, action) => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return wait(state);
    case types.LOGIN_FAILURE:
      return fail(state);
    case types.LOGIN_SUCCESS:
      return {
        ...reset(state),
        authenticated: true,
        token: action.token,
        user: action.user,
      };
    case types.GET_ACCOUNT_BALANCE_SUCCESS:
      return {
        ...reset(state),
        user: {
          ...state.user,
          wallets: action.wallets,
        },
      };
    case types.LOGIN_RESET:
    case types.LOGOUT:
      return { ...authInitial, authenticated: false };
    default:
      return state;
  }
};

const getAccountBalanceInital = getInitial({});

export const getAccountBalance = (state = getAccountBalanceInital, action) => {
  switch (action.type) {
    case types.GET_ACCOUNT_BALANCE_REQUEST:
      return wait(state);
    case types.GET_ACCOUNT_BALANCE_SUCCESS:
      return reset(state);
    case types.GET_ACCOUNT_BALANCE_FAILURE:
      return fail(state);
    default:
      return state;
  }
};

export const messages = (state = [], action) => {
  switch (action.type) {
    case types.GET_MESSAGES_SUCCESS:
      const payload = Array.isArray(action.arrMessages)
        ? [...state, ...action.arrMessages]
        : [...state, action.arrMessages];
      return payload.filter((value, index, self) => index === self.findIndex((t) => t._id === value._id));
    case types.READ_MESSAGE_SUCCESS:
      return state.filter((s) => s._id !== action.messageId);
    default:
      return state;
  }
};

const transactionsInitial = getInitial({
  data: null,
});

export const transactions = (state = transactionsInitial, action) => {
  switch (action.type) {
    case types.GET_TRANSACTIONS_REQUEST:
      return { ...wait(state), data: null };
    case types.GET_TRANSACTIONS_FAILURE:
      return fail(state);
    case types.GET_TRANSACTIONS_SUCCESS:
      return {
        ...reset(state),
        data: action.data,
      };
    case types.GET_TRANSACTIONS_RESET:
    case types.LOGOUT:
      return transactionsInitial;
    default:
      return state;
  }
};
