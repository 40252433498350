import { take, put, cancel, fork, call, delay, select } from 'redux-saga/effects';
import moment from 'moment';
import types from '../actionTypes';
import { apiPost, LOGIN_REQUEST } from './callApi';
import { loginSuccess, loginFailure, logout } from '../actionCreators/authentication';
import i18n from 'i18next';
import { getLS, setLS, removeAccountData } from '../../helpers/localStorage';
import { hasToken } from '../selectors';
import apiRoutes from '../../constants/apiRoutes';
import settings from '../../configs/settings';

const validate = (username, password) => username && password;

function* processAuthenticationRequest({ username, password }) {
  try {
    if (!validate(username, password)) {
      yield put(loginFailure(new Error('Wrong Credentials')));
      return;
    }

    const params = {
      username: username,
      password,
      skinId: settings.skinId,
      parentId: settings.skinId,
    };

    const response = yield call(apiPost, apiRoutes.LOGIN, params, {}, LOGIN_REQUEST);
    const { token, exp, ...user } = response.data.result;

    const expiresAt = moment().add(exp, 'seconds').valueOf();
    window?.Tipster?.login?.(token, 4, i18n.language, user?.wallets?.[0]?.currency);
    setLS('token', token);
    setLS('user', JSON.stringify(user));
    setLS('expiresAt', expiresAt);
    yield put(loginSuccess(token, user));
  } catch (e) {
    switch(e.response.data.message.key) {
      case 'document_not_found':
        alert('Invalid username or password')
        break;
      case 'incorrect_password':
        alert('Invalid password')
        break;
    }
    yield put(loginFailure(e));
  }
}

function* watchTokenExpiration() {
  const expiresAt = parseInt(getLS('expiresAt'), 10);
  if (moment().valueOf() >= expiresAt) {
    yield put(logout());
    window?.Tipster?.logout(4);
  }
  do {
    yield delay(10000);
  } while (moment().valueOf() < expiresAt);
  if (yield select(hasToken)) { 
    yield put(logout());
    window?.Tipster?.logout(4);
  }
}

export function* watchLogout() {
  while (true) {
    yield take(types.LOGOUT);
    removeAccountData();
  }
}

function* watchAuthentication() {
  let forked = null;
  let expirationWatcher = null;

  while (true) {
    const action = yield take(types.LOGIN_REQUEST); // Wait for LOGIN_REQUEST action

    const token = getLS('token');
    if (token) {
      const user = JSON.parse(getLS('user'));
      const expiresAt = parseInt(getLS('expiresAt'), 10);

      if (moment().valueOf() >= expiresAt) {
        yield put(logout());
        window?.Tipster?.logout(4);
      } else {
        yield put(loginSuccess(token, user));
        window?.Tipster?.login?.(token, 4, i18n.language, user?.wallets?.[0]?.currency);
        expirationWatcher = yield fork(watchTokenExpiration);
        yield take(types.LOGOUT);
        yield cancel(expirationWatcher);
        setLS('token', null);
        window?.Tipster?.logout(4);
      }
    } else {
      yield put(logout());
      window?.Tipster?.logout(4);
    }

    if (forked) yield cancel(forked);
    forked = yield fork(processAuthenticationRequest, action);
    const result = yield take([types.LOGIN_SUCCESS, types.LOGIN_FAILURE]);

    if (result.type === types.LOGIN_SUCCESS) {
      expirationWatcher = yield fork(watchTokenExpiration);
      yield take(types.LOGOUT);
      yield cancel(expirationWatcher);
      setLS('token', null);
      window?.Tipster?.logout(4);
    }
  }
}

export default watchAuthentication;
