import { classNames } from '../../helpers/pureFunctions';
import FPP_IMG from './FPP.svg';
import './FPP.scss';

const FPP = ({ amount, size }) => {
  const FPPClassnames = classNames('fpp', {
    [`fpp--${size}`]: size,
  });

  return (
    <div className={FPPClassnames}>
      <img src={FPP_IMG} alt="fpp" className="fpp__img" />
      <span className="fpp__amount">{amount}</span>
    </div>
  );
};

export default FPP;
