import types from '../actionTypes';

export const getAccountBalanceRequest = () => ({
  type: types.GET_ACCOUNT_BALANCE_REQUEST,
});

export const getAccountBalanceSuccess = (wallets) => ({
  type: types.GET_ACCOUNT_BALANCE_SUCCESS,
  wallets,
});

export const getAccountBalanceFailure = (e) => ({
  type: types.GET_ACCOUNT_BALANCE_FAILURE,
  exception: e,
});
