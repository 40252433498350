import { take, put, cancel, fork, call, delay, select } from 'redux-saga/effects';
import settings from "../../configs/settings";
import apiRoutes from '../../constants/apiRoutes';
import { setLS } from '../../helpers/localStorage';
import { loginFailure, loginSuccess } from '../actionCreators/authentication';
import types from '../actionTypes';
import { apiPost, LOGIN_REQUEST } from './callApi';
import moment from 'moment';

function* processExternalAuthenticationRequest({googleToken}) {
  try {
    const params = {idToken: googleToken, skinId: settings.skinId};
    const response = yield call(apiPost, apiRoutes.GOOGLE_AUTH, params, {}, LOGIN_REQUEST)
    const { token, exp, ...user } = response.data.result;
    const expiresAt = moment().add(exp, 'seconds').valueOf();
    setLS('token', token);
    setLS('user', JSON.stringify(user));
    setLS('expiresAt', expiresAt);
    yield put(loginSuccess(token, user));
  } catch (error) {
    switch(error.response.data.message.key) {
      case 'document_not_found':
        alert('Invalid username or password')
        break;
      case 'incorrect_password':
        alert('Invalid password')
        break;
    }
    yield put(loginFailure(error));
  }
}

function* watchExternalAuthentication(action) {
  let forked =  null;
  while(true) {
    const action = yield take(types.AUTH_REQUEST);
    if (forked) yield cancel(forked);
    forked = yield fork(processExternalAuthenticationRequest, action);
  }
}

export default watchExternalAuthentication;