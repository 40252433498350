import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../../helpers/pureFunctions';
import './FAQWidget.scss';
import { configs } from '../../../configs/configs';

const FAQ_WIDGET_INFO = [
  { question: 'question1' },
  { question: 'question2' },
  { question: 'question3' },
  { question: 'question4' },
  { question: 'question5' },
];

const FAQWidget = () => {
  const WEBSITE_NAME = configs.websiteName;

  const { t } = useTranslation();
  const [openItem, setOpenItem] = useState(null);

  const accordionToggleHandler = (id) => {
    openItem === id ? setOpenItem(null) : setOpenItem(id);
  };

  const faqWidgetClasses = classNames('faq-widget-wrapper', {
    mobile: isMobile,
  });

  return (
    <div className={faqWidgetClasses}>
      {FAQ_WIDGET_INFO.map((info, index) => (
        <div key={index} className='faq-widget-container'>
          <div className={`faq-widget-container__header ${openItem === index ? 'show' : 'hide'}`}>
            <span className='faq-widget-container__header--title'>{t(`faqWidgetInfo.${info.question}.title`)}</span>
            <span className='faq-widget-container__header--icon' onClick={() => accordionToggleHandler(index)}>
              {openItem === index ? '-' : '+'}
            </span>
          </div>
          <div className={`faq-widget-container__body ${openItem === index ? 'show' : 'hide'}`}>
            <span className='faq-widget-container__body--item'>
              {t(`faqWidgetInfo.${info.question}.description`, { websiteName: WEBSITE_NAME })}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQWidget;
