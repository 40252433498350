import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { configs } from '../../../configs/configs';
import countryCodes from '../../../configs/countryCodeList.json';
import { useOutSideClicker } from '../../../hooks/useOutSideClicker';
import './Flag.scss';

const LANGUAGES = {
  GB: 'en',
  IT: 'it',
  JP: 'jp',
  KO: 'ko',
  TR: 'tr',
  ES: 'es',
};

const Flag = () => {
  const { i18n } = useTranslation();
  const [selectedFlag, setSelectedFlag] = useState(configs.LANGUAGES[0]);
  const { ref, isShow, setIsShow } = useOutSideClicker(false);

  const handleClick = () => {
    setIsShow((openLangList) => !openLangList);
  };

  const handleSelectFlag = (lang) => {
    setSelectedFlag(lang);
    i18n.changeLanguage(LANGUAGES[lang]);
    setIsShow(false);
    const iframe = document.getElementById('tipsterPlugin');
    if (iframe) {
      iframe.src = iframe.src.replace(/(\?|&)lcode=[^&]*/, `&lcode=${LANGUAGES[lang]}`)
    }
  };

  return (
    <div className='language-selector'>
      <div className='language-selector__flag' onClick={handleClick}>
        <img
          alt='United States'
          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${selectedFlag}.svg`}
        />
      </div>
      {configs.LANGUAGES.length > 1 && isShow && (
        <div className='language-selector__list' ref={ref}>
          {configs.LANGUAGES.map((lang) => (
            <div key={lang + 'lang'} className='language-selector__list-row' onClick={() => handleSelectFlag(lang)}>
              <img
                key={lang}
                alt='United States'
                src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${lang}.svg`}
              />
              {countryCodes[lang.toLowerCase()]}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Flag;
