import { useCallback, memo, useEffect } from 'react';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../store/actionCreators/dialogManager';
import { requestReadMessage } from '../../../store/actionCreators/messages';
import './TournamentFinished.scss';
import { getAccountBalanceRequest } from '../../../store/actionCreators/getAccountBalance';
import DialogTemplate from '../../common/Modal';
import { useFormatCurrency } from '../../../hooks/useCorrency';

const TournamentFinished = ({ dialogType, info }) => {
  const {
    data: {
      tournamentName,
      tournamentId,
      score,
      playerPosition,
      winning,
    },
  } = info;
  const dispatch = useDispatch();
  const formatCurrency = useFormatCurrency();

  const onClose = useCallback(() => {
    dispatch(closeDialog(dialogType));
    dispatch(requestReadMessage(info._id));
  }, [dispatch, dialogType, info._id]);

  useEffect(() => {
    dispatch(getAccountBalanceRequest());
  }, []);

  const isTypeTourTicket = winning?.type === 'tourTicket';

  return (
    <DialogTemplate
      className="tournament-reults-pop-up"
      dialogName="tournamentReultsDialog"
      onClose={onClose}
      textParams={{
        tournamentName
      }}
    >
      <div className="tournament-results">
        <span className="tournament-results__text">{`${t('tournamentResults')}:`}</span>
        <div className="tournament-results__info-wrapper">
          <div className="tournament-results__info-item">
            <span className="tournament-results__info-item-label">{`${t('score')}:`}</span>
            <span className="tournament-results__info-item-value">{score}</span>
          </div>
          <div className="tournament-results__info-item">
            <span className="tournament-results__info-item-label">{`${t('yourPosition')}:`}</span>
            <span className="tournament-results__info-item-value">{playerPosition}</span>
          </div>
          <div className="tournament-results__info-item">
            <span className="tournament-results__info-item-label">{`${t('tournamentId')}:`}</span>
            <span className="tournament-results__info-item-value tournament-results__info-item-value--white">
              {tournamentId}
            </span>
          </div>
        </div>
        {(winning?.amount > 0 || isTypeTourTicket || winning?.prizeName) && (
          <div className="tournament-results__winning-wrapper">
            <div className="tournament-results__winning">
              <div className="tournament-results__winning-icon-wrapper">
                <div className='tournament-results__icon' >
                  <i className={`icon-${isTypeTourTicket ? 'ticket' : 'winning'}`}></i>
                </div>
              </div>
              <div className="tournament-results__winning-info-wrapper">
                {!isTypeTourTicket && (
                  <span className="tournament-results__winning-info-title">
                    {winning?.prizeName
                      ? winning?.prizeName
                      : formatCurrency(winning?.amount, winning?.currency)}
                  </span>
                )}
                <span
                  className="tournament-results__winning-info-subtitle"
                  dangerouslySetInnerHTML={{
                    __html: isTypeTourTicket
                      ? t('winningTicket', { tournamentName: winning?.targetName })
                      : winning?.prizeName
                      ? ''
                      : t('addedToYourBalance'),
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </DialogTemplate>
  );
};

export default memo(TournamentFinished);
