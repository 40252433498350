import React from 'react';
import { classNames } from '../../../helpers/pureFunctions';
import './Button.scss';

function Button({ children, onClick, disabled, variant, size, icon }) {
  const buttonClassNames = classNames({
    button: true,
    btn: true,
    [`button--${variant}`]: variant,
    [`button--${size}`]: size,
  });

  return (
    <button disabled={!!disabled} onClick={onClick} className={buttonClassNames}>
      {icon && <i className={`icon-${icon} button__icon`} />}
      {icon && <span className="button__text">{children}</span>}
      {!icon && children}
    </button>
  );
}

export default Button;
