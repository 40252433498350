import classNames from 'classnames';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import './Modal.scss';

const DialogTemplate = ({
  className,
  dialogName,
  textParams,
  children,
  onConfirm,
  onClose,
  onCloseBtn,
  confirmText,
  closetext,
  modalSkin,
  titleParams,
}) => {
  const { t } = useTranslation();
  const { isLandscape } = useMobileOrientation();
  const classNameToUse = className || '';

  const closeTop = () => {
    if (onClose) {
      onClose();
    } else {
      onConfirm ? onConfirm() : '';
    }
  };

  const dialogTemplateClasses = classNames('dialog-template__inner', {
    [classNameToUse]: !!className,
    mobile: isMobile,
    landscape: isMobile && isLandscape,
  });

  return (
      <div className="dialog-template">
        <div className="dialog-template__overlay" />
        {modalSkin ? (
          <>{children}</>
        ) : (
          <div className={dialogTemplateClasses}>
            <div className="dialog-template__close-wrapper">
              <i className="dialog-template__close icon-cross" onClick={closeTop} />
            </div>
            {dialogName && (
              <>
                <span
                  className="dialog-template__title"
                  dangerouslySetInnerHTML={{ __html: t(`${dialogName}.title`, titleParams ?? {}) }}
                />
                <p
                  className="dialog-template__text"
                  dangerouslySetInnerHTML={{ __html: t(`${dialogName}.text`, textParams ?? {}) }}
                />
              </>
            )}
            <div className="dialog-template__body">
              {!!children && children}
              {(onConfirm || onCloseBtn) && (
                <div className="dialog-template__buttons-wrapper">
                  {onConfirm && (
                    <button onClick={onConfirm} className="dialog-template__confirm-button success-filled-btn">
                      {confirmText || t('confirm')}
                    </button>
                  )}
                  {onCloseBtn && (
                    <button onClick={onClose} className="dialog-template__confirm-close">
                      {closetext || t('close')}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
  );
};

export default DialogTemplate;
