import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { classNames } from '../../helpers/pureFunctions';
import HeaderBannerWidget from './HeaderBannerWidget';
import './HowToPlayPage.scss';
import { configs } from '../../configs/configs';
import TournamentCategoryWidgetInfo from '../../configs/howToPlayPageTournamentCategoriesInfo.json';
import TournamentCategoryWidget from './TournamentCategoryWidget';
import TournamentTypesWidget from './TournamentTypesWidget';
import TournamentPrizeAndBuyInTypesWidget from './TournamentPrizeAndBuyInTypesWidget';
import YoutubeVideoWidget from './YoutubeVideoWidget';
import StepsToPlayWidget from './StepsToPlayWidget';
import FAQWidget from './FAQWidget';

const HowToPlayPage = () => {
  const WEBSITE_NAME = configs.websiteName;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [greetingText, setGreetingText] = useState('showMore');

  useEffect(() => {
    isMobile ? setGreetingText('showMore') : setGreetingText('showLess');
  }, [isMobile]);

  const showToggleHandler = () => {
    greetingText === 'showMore' ? setGreetingText('showLess') : setGreetingText('showMore');
  };

  const pageContainerClasses = classNames('how-to-play-page-container', {
    mobile: isMobile,
  });

  return (
    <div className='how-to-play-page'>
      <div className={pageContainerClasses}>
        <HeaderBannerWidget />
        <div className='how-to-play-page__title'>{t('gameOfSlots')}</div>
        <div className='how-to-play-page__greeting-text'>
          {t(`howToPlayPageGreetingText.${greetingText}`, { websiteName: WEBSITE_NAME })}
          {isMobile && (
            <span onClick={showToggleHandler} className='show-more'>
              {t(greetingText)}
            </span>
          )}
        </div>
        <div className='tournaments-category-section'>
          {TournamentCategoryWidgetInfo.map((info) => (
            <TournamentCategoryWidget
              key={info.id}
              icon={info.icon}
              text={info.description}
              comingSoon={info.hasComingSoon}
            />
          ))}
        </div>
        <div className='tournament-types-section'>
          <div className='tournament-types-section__title'>{t('gameOfSlotsTypes')}</div>
          <TournamentTypesWidget />
          {configs.hasYoutubeLinkOnHowToPlayPage ? (
            <>
              <div className='youtube-section__title'>{t('watchVideoTutorialHowToPlay')}</div>
              <YoutubeVideoWidget
                src='https://www.youtube.com/embed/iUtRtZe_Alc'
                height={isMobile ? '250px' : '415'}
                width={isMobile ? '100%' : '660'}
              />
            </>
          ) : (
            <TournamentPrizeAndBuyInTypesWidget />
          )}
        </div>
        <div className='steps-to-play-section'>
          <div className='steps-to-play-section__title'>
            {isMobile && <span className='steps-count'>4</span>} {t('stepsToPlayGameOfSlots')}
          </div>
          <StepsToPlayWidget />
        </div>
        <div className='faq-section'>
          <div className='faq-section__title'>{t('faq')}</div>
          <FAQWidget />
        </div>
      </div>
    </div>
  );
};

export default HowToPlayPage;
