import React from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../../helpers/pureFunctions';
import './TournamentPrizeAndBuyInTypesWidget.scss';

const TOURNAMENT_PRIZE_AND_BUY_IN_WIDGET_INFO = [
  { id: 1, icon: 'star-circle', type: 'buyIn', title: 'casinoTournamentsBuyInTypes', info: ['text1', 'text2', 'text3', 'text4'] },
  { id: 2, icon: 'gift', type: 'prize', title: 'casinoTournamentsPrizeTypes', info: ['text1', 'text2', 'text3', 'text4', 'text5'] }
]

const TournamentPrizeAndBuyInTypesWidget = () => {

  const { t } = useTranslation();

  const widgetWrapperClasses = classNames('prize-and-buy-in-types-widget-wrapper', {
    mobile: isMobile,
  });

  return (
    <div className={widgetWrapperClasses}>
      {TOURNAMENT_PRIZE_AND_BUY_IN_WIDGET_INFO.map(item => (
        <div key={item.id} className="prize-and-buy-in-types-widget__item">
          <div className='icon-row'><i className={`icon-${item.icon}`} /></div>
          <div className="title-row">{t(`tournamentPrizeAndBuyInTypesWidget.${item.title}`)}</div>
          <div className="info-row">
            <ul className='info-row__list'>
              {item.info.map(e => <li className='info-row__list--item'>{t(`tournamentPrizeAndBuyInTypesWidget.${item.type}.${e}`)}</li>)}
            </ul>
          </div>
        </div>
      ))}
    </div>
  )
}

export default TournamentPrizeAndBuyInTypesWidget