import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setLS } from '../../../helpers/localStorage';
import Flag from '../Flag';
import { isMobile } from 'react-device-detect';
import { classNames } from '../../../helpers/pureFunctions';
import './Navigation.scss';
import { configs } from '../../../configs/configs';

const Navigation = ({ closeBurger }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const languageListClasses = classNames('header__navigation-item', 'header__navigation-item--language', {
    mobile: isMobile,
  });

  const headerNavClasses = classNames('header__navigation', {
    mobile: isMobile,
  });

  const setPageCategory = (name) => {
    setLS('page', name);
    closeBurger && closeBurger();
  };

  return (
    <ul className={headerNavClasses}>
      {configs?.navigation?.length && configs?.navigation.map(({ path, name }) => (
        <li
          key={name}
          className={`header__navigation-item ${path === pathname ? 'header__navigation-item--active' : ''}`}>
          <Link to={path} onClick={() => setPageCategory(name)}>
            {t(name)}
          </Link>
        </li>
      ))}
      { isMobile && (
        <li className={languageListClasses}><span>{t('language')}</span><span> <Flag/> </span></li>
      )}
    </ul>
  );
};

export default Navigation;
