import React from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DescriptionWrapper from './DescriptionWrapper';
import './StepsToPlayWidgetMobile.scss';

const StepsToPlayWidgetMobile = ({ data, scrollCount = 1 }) => {
  const { t } = useTranslation();
  const elements = useRef()
  const itemWidth = 336;
  const [step, setStep] = useState(1);

  const scrollRight = () => {
    const gameContainer = elements.current;
    if (!(gameContainer.scrollLeft % itemWidth)) {
      gameContainer.scrollLeft += itemWidth * scrollCount;
      step < data.length && setStep(prev => prev + 1)
    }
  }

  const scrollLeft = () => {
    const gameContainer = elements.current;
    if (!(gameContainer.scrollLeft % itemWidth)) {
      gameContainer.scrollLeft -= itemWidth * scrollCount;
      step > 1 && setStep(prev => prev - 1)
    }
  }

  return (
    <div className='steps-to-play-widget-mobile'>
      <div className='steps-to-play-widget-mobile__header'>
        <div className="header-left-section">
          <span className="header-left-section__steps-count">{t('step')} {step}</span>
          <span className="header-left-section__title">{t(`StepsToPlayWidget.${data[step - 1].title}`)}</span>
        </div>
        <div className="header-right-section">
          <span className={`header-right-section__button ${step === 1 ? 'disabled' : ''}`} onClick={scrollLeft}><i className='icon-arrow-right' /></span>
          <span className={`header-right-section__button ${step === data.length ? 'disabled' : ''}`} onClick={scrollRight}><i className='icon-arrow-right' /></span>
        </div>
      </div>
      <div className='steps-to-play-widget-mobile__body'>
        <div className='steps-to-play-widget-mobile__body-elements' ref={elements}>
          {data.map(d => (
            <div key={d.id} className='steps-to-play-widget-mobile__body-element'>
              <DescriptionWrapper data={d} />
              <div className={`element__image-wrapper img-${d.img}`}>
                {/* <img src={`/images/howToPlayPage/step${d.img}-mobile.png`} alt='ee' /> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default StepsToPlayWidgetMobile