import types from '../actionTypes';

export const successMessages = (arrMessages) => ({
  type: types.GET_MESSAGES_SUCCESS,
  arrMessages,
});

export const requestReadMessage = (messageId) => ({
  type: types.READ_MESSAGE_REQUEST,
  messageId,
});

export const successReadMessage = (messageId) => ({
  type: types.READ_MESSAGE_SUCCESS,
  messageId,
});
