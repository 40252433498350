import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getDialogs } from '../../store/selectors';
import TournamentFinished from './TournamentFinished';
// import Bonus from './Bonus';
import { useMobileOrientation, isMobile } from 'react-device-detect';
import SpinAndGoStarted from './SpinAndGoStarted';

const Dialogs = () => {
  const { isPortrait, isLandscape } = useMobileOrientation();
  const dialogs = useSelector(getDialogs);
  const tournamentFinishedDialogs = useMemo(
    () => Object.keys(dialogs).filter((key) => key.includes('tourFinished')),
    [dialogs]
  );
  // const newBonusDialogs = useMemo(() => Object.keys(dialogs).filter((key) => key.includes('newBonus')), [dialogs]);
  const deviceInfo = { isMobile, isLandscape, isPortrait };

  return (
    <>
      {tournamentFinishedDialogs.length > 0 &&
        tournamentFinishedDialogs.map((dialogKey) => {
          return dialogs[dialogKey] && <TournamentFinished key={dialogKey} {...dialogs[dialogKey]} {...deviceInfo} />;
        })}
      {/* {newBonusDialogs.length > 0 &&
        newBonusDialogs.map((dialogKey) => {
          return dialogs[dialogKey] && <Bonus key={dialogKey} {...dialogs[dialogKey]} {...deviceInfo} />;
        })} */}
      {dialogs?.spinAndGoStarted && <SpinAndGoStarted {...dialogs?.spinAndGoStarted} />}
    </>
  );
};

export default Dialogs;
