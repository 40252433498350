import FPP from "../components/FPP";
const locales = {
  BRL: "pt-BR",
  CNY: "zh-CN",
  USD: "en-EN",
  EUR: "en-FR",
  HKD: "zh-HK",
  IDR: "id-ID",
  IRR: "fa-IR",
  ILS: "he-IL",
  KZT: "kk-KZ",
  MYR: "ms-MY",
  PEN: "es-PE",
  GBP: "en-GB",
  RUB: "ru-RU",
  SGD: "en-SG",
  THB: "th-TH",
  TND: "fr-TN",
  TRY: "tr-TR",
  VND: "vi-VN",
};

export const useFormatCurrency = () => {

  const formatAmount = (amount, currency, fixedDecimalCount, type) => {
    if (amount === undefined || amount === null || Number.isNaN(amount)) return;
    if (!currency) {
      if (type === "tourTicket") {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <i
              style={{
                marginRight: "4px",
                fontSize: "10px",
                transform: "translateY(0.5px)",
              }}
              className="icon-ticket"
            />
            <span>{String(amount)}</span>
          </div>
        );
      }
      return String(amount);
    }
    if (currency === "FPP") {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }} className="FPP">
          <FPP />
          <i style={{ marginLeft: 2 }}>{String(new Intl.NumberFormat().format(amount))}</i>
        </div>
      );
    }
  
    const convertedAmount = amount;

    let formattedAmount = "";
    let suffix = "";

    if (convertedAmount >= 1000000) {
      formattedAmount = (Math.floor(convertedAmount / 1000000 * 100) / 100).toFixed(2);
      suffix = " M";
    } else if (convertedAmount >= 100000) {
      formattedAmount = (Math.floor(convertedAmount / 1000 * 100) / 100).toFixed(2);
      suffix = " K";
    } else {
      formattedAmount = convertedAmount.toFixed(fixedDecimalCount ?? 2);
    }

    const selectedLocale = locales[currency] || "tr-TR";
    const currencyLabel = currency;
    const minimumFractionDigits = fixedDecimalCount ?? 2;

    const formatter = new Intl.NumberFormat(selectedLocale, {
      style: "currency",
      currency: currencyLabel,
      minimumFractionDigits,
    });

    const formattedNumber = formatter.format(formattedAmount);

    return `${formattedNumber}${suffix}`;
  };

  return formatAmount;
};
