import React from 'react'
import { useTranslation } from 'react-i18next';
import './ComingSoon.scss';

const ComingSoon = () => {

    const { t } = useTranslation();

    return (
        <div className='coming-soon'>{t('comingSoon')}</div>
    )
}

export default ComingSoon